import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { Model } from '../model/model';
import { LanguageEnum } from '../model/enum/language-enum';
import { ModelResponse } from '../model/response/model-response';
import { Item } from '../model/Item';
import { TrashListResponse } from '../model/response/trash-response';
import { TrashRestoreRequest } from '../model/request/trashRestore-request';

@Injectable({providedIn: 'root'})
export class TrashService {

  userUrl: string
  httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {};
    this.userUrl = enviroment.BASE_URL_API + '/user';
  }

  getTrashList(userID: string) : Observable<TrashListResponse[]> {
    return this.http.get<TrashListResponse[]>(this.userUrl +'/' + userID + '/garbage', this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<TrashListResponse[]>(`getTrashList`))
    );
  }

  trashRestore(userID: string , infoTrash: TrashRestoreRequest[] ) : Observable<HttpResponse<any>> {
    return this.http.put<TrashListResponse>(this.userUrl +'/' + userID + '/garbage/restore', infoTrash, { observe: 'response' });
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
