import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Project } from 'src/app/model/form/project';
import { Image } from 'src/app/model/Image';
import { Load } from 'src/app/model/mockup/load-mockup';
import { Share } from 'src/app/model/share';
import { AccountService } from 'src/app/service/account.service';
import { ApplicationService } from 'src/app/service/application.service';
import { ProjectService } from 'src/app/service/project.service';
import { CustomValidator } from 'src/shared/custom-validator';
import { CreateProjectPostReq } from '../../../model/request/create-project-post-req';
import { CreProVcuPostReq } from '../../../model/request/cre-pro-vcu-post-req';
import { CreProUserSharingPostReq } from '../../../model/request/cre-pro-user-sharing-post-req';
import { UpdateProjectPutReq } from '../../../model/request/update-project-put-req';
import { UpdProUserSharingPutReq } from '../../../model/request/upd-pro-user-sharing-put-req';
import { Item } from 'src/app/model/Item';
import { FileService} from 'src/app/service/file.service';
import { HttpResponse } from '@angular/common/http';
import { CategoryService } from '../../../service/category.service';
import { FileUpload } from 'primeng/fileupload';
import { Vcu } from '../../../model/form/vcu';
import { Equipment } from 'src/app/model/equipment';

@Component({
  selector: 'app-register-project',
  templateUrl: './register-project.component.html',
  styleUrl: './register-project.component.css'
})
export class RegisterProjectComponent implements OnInit, OnChanges { 
    
  public form!: FormGroup;

  @Input()
  showDialog: boolean = true;

  @Input()
  editMode: boolean = false;

  @Input()
  project: Project | null = null;

  @Output()
  closeDialog = new EventEmitter<any>();

  @ViewChild('dtShare') dtShare: Table | undefined;

  @ViewChild('fileUpload') fileUploadComponent: FileUpload | undefined;

  loadingShare: boolean = false;

  dialogHeader: string = '';
  active: number = 0;
  stepItems: MenuItem[] = [];
  modalpage: string[];
  applicationOptions: Item[] = [];
  dealerOptions: object[];
  vcusOptions: GenericEquipment[] = [];
  categoriesOptions: GenericEquipment[];
  productsOptions: GenericEquipment[][] = [];
  message: Message[]
  loadingImage: boolean;
  idAux: number = 0;
  userID: string = '';
  projectID: string = '';
  labelBtnImagePage: string = '';
  selectedFiles: File[] | null = null;
  isLoadingEquipments: boolean = false;
  isClicked:boolean = false;

  constructor(private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private applicationService: ApplicationService,
    private projectService: ProjectService,
    private fileService: FileService,
    private categoryService: CategoryService,
    private messageService: MessageService) {
    this.modalpage = this.goPage(1);
    
    // this.applicationOptions = Load.APPLICATION;
    this.dealerOptions = Load.DEALER;
    //TODO gerar ID unico em comum para os 3 dropdowns
    // e retornar apenas 2 IDs, da VCU e do equipamento
    // ignorar a Categoria, usada apenas para filtro
    // porque assim fica mais facil de identificar o objeto e realizar o vinculo
    this.vcusOptions = [];
    this.categoriesOptions = [];
    this.productsOptions = [];
    this.message = [];
    this.loadingImage = false;
    if(this.project && this.project.images.length > 0)
      this.project.primaryImage = this.project?.images[0];
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.project?.currentValue) {
      this.project = changes.project.currentValue;
      this.applicationOptions.filter(item => this.project && item.id === this.project.application?.id).map(it => (this.project ? this.project.application = it : null));
      if(this.project)
        this.createForm(this.project);
    }
    if(changes.showDialog !== undefined && changes.showDialog?.currentValue !== changes.showDialog?.previousValue && changes.showDialog?.currentValue) {
      this.openingDialog()
    }
  }

  ngOnInit() {
    this.messageService.clear();
    this.isClicked = false;
    if(!this.project) {
      this.project = new Project();
      this.createForm(this.project);
    }
    this.modalpage = this.goPage(1);
    this.active = 0;
    this.translocoService.selectTranslateObject('project.register.steps').subscribe(value => {
      this.stepItems = value
      if(this.editMode) {
        this.stepItems = [this.stepItems[0], this.stepItems[1], this.stepItems[2]]
      }
    });
  }

  openingDialog() {
    let token = sessionStorage.getItem('token');
    if(token) this.accountService.findUserActive().subscribe(
      data =>{
        localStorage.setItem('idUser', data.userID);
        localStorage.setItem('nameUser', data.name);
        localStorage.setItem('language', data.language);
        this.userID = data.userID;
        this.FindUserVcus(data.userID).then(_ => {
          this.findCategories().then(_ => {
            this.applicationService.getAllApplication().subscribe(data => {
              this.applicationOptions = ApplicationService.toItemList(data);
              this.activatedRoute.params.subscribe(params => {
                this.projectID = params['id'];
                if(this.editMode && this.userID && this.projectID) {
                  this.isLoadingEquipments = true;
                  this.projectService.getProject(this.userID, this.projectID).subscribe(dataProject => {
                    this.project = ProjectService.toProject(dataProject);
                    if(this.project)
                      this.loadForm(this.project);
                  });
                }
              });
            });
          });
        });
      }
    );
  }

  loadForm(project: Project) {
    
    this.isLoadingEquipments = true;
    this.createForm(project);
    this.loadEquipment(project);
    this.isLoadingEquipments = false;
  }

  createForm(project: Project) {
    this.form = this.formBuilder.group({
      name: [project.name, CustomValidator.getRequired()],
      application: [project.application, CustomValidator.getRequired()],
      manufacturer: [project.vehicle?.manufacturer],
      model: [project.vehicle?.model],
      yearDate: [project.vehicle?.yearDate],
      tags: [project.tags],
      detail: [project.detail],
      genericEquipments: this.formBuilder.array([]),
      images: [project.images],
      primaryImage: [project.primaryImage],
      email: ['', CustomValidator.getEmailShare()],
      sharedWith: [project.sharedWith],
      favorite: [project.favorite]
    })
  }

  resetForm() {
    // this.form.reset();
      this.project = new Project();
      this.createForm(this.project);
    if (this.project) {
      this.project.sharedWith = [];
    }
    if (this.fileUploadComponent) {
      this.fileUploadComponent.clear();
    }    
  }


  onSubmit() {
    this.checkInputs();
    if(this.form.valid) {
      if(!this.isClicked){
        this.isClicked = true;
        if(!this.editMode) {
          this.f.primaryImage.setValue(this.project?.primaryImage);
          this.f.sharedWith.setValue(this.project?.sharedWith);
          this.projectService.createProject(this.userID, this.converterFormToCreateProjectPostReq(this.form)).subscribe({
            next: project =>{
              if(project) {
                this.projectID = project.projectID;
                this.translocoService.selectTranslateObject('project.register').subscribe(value => {
                  setTimeout(() => {
                    this.messageService.clear();
                    this.messageService.add({ key: 'toastRegisterProject', severity: 'success', summary: value.header, detail: value.postMessage});
                  }, 3000);
                }).unsubscribe();
                this.closeModal();
                this.isClicked = false;
              } else {
                this.translocoService.selectTranslateObject('error.connection').subscribe(value => {
                  setTimeout(() => {
                    this.messageService.clear();
                    this.messageService.add({ key: 'toastRegisterProject', severity: 'error', summary: value.header, detail: value.message});
                  }, 3000);
                }).unsubscribe();
              }
            },
            error: _=>{}
          });
        } else {
          this.projectService.updateProject(this.userID, this.projectID, this.converterFormToUpdateProjectPutReq(this.form)).subscribe({
            next: project => {
              if(project) {
                this.messageService.clear();
                this.translocoService.selectTranslateObject('project.register').subscribe(value => {
                  this.messageService.add({ key: 'toastRegisterProject', severity: 'success', summary: value.header, detail: value.putMessage});
                }).unsubscribe();
                this.closeModal();
                this.isClicked = false;
              } else {
                this.translocoService.selectTranslateObject('error.connection').subscribe(value => {
                  setTimeout(() => {
                    this.messageService.clear();
                    this.messageService.add({ key: 'toastRegisterProject', severity: 'error', summary: value.header, detail: value.message});
                  }, 3000);
                }).unsubscribe();
              }
            },
            error: _=>{}          
          });
        }
      }
    }
  }

  checkInputs() {
    if(this.f.name.invalid) this.f.name.markAsDirty()
    if(this.f.application.invalid) this.f.application.markAsDirty()
  }

  addGenericEquipment(type: string) {
    if(TypeEquipment.VCU.valueOf() === type) {
      
      const control = this.form.get('genericEquipments') as FormArray;
      control.push(this.formBuilder.group({
        vcu: [{}, CustomValidator.getRequired()]
      }))
    } else if(TypeEquipment.EQUIPMENT.valueOf() === type) {
      const control = this.form.get('genericEquipments') as FormArray;
      control.push(this.formBuilder.group({
        category: [{}, CustomValidator.getRequired()],
        product: [{}, CustomValidator.getRequired()]
      }))
    }
  }

  removeGenericEquipment(index: number) {
    const control = this.form.get('genericEquipments') as FormArray;
    control.removeAt(index)
  }
  
  getGenericEquipments() {
    return (this.form.get('genericEquipments') as FormArray).controls;
  }

  getGenericType(genericEquipment: any) {
    if(genericEquipment.vcu)
      return TypeEquipment.VCU.valueOf();
    else
      return TypeEquipment.EQUIPMENT.valueOf();
  }

  convertFileToImage(file: File | any) {
    this.idAux += 1;
    return new Image(this.idAux, 
          file.name, 
          file.objectURL?.changingThisBreaksApplicationSecurity,
          file.size);
  }

  removeImage(image: File | any) {
    if(this.project) {
      let index = this.project.images.indexOf(image);
      this.project.images.splice(index, 1);
      if(image?.href === this.project.primaryImage?.href && this.project.images.length > 0) {
        this.project.primaryImage = this.project.images[0];
      }
    }
  }

  addShare() {
    if( this.project && this.f.email.valid) {
      this.accountService.FindUserEmail(this.f.email.value).subscribe({
        next: (data) =>{
          let userId = localStorage.getItem('idUser');
          if(data.id != userId){
            this.project!.sharedWith = [...this.project!.sharedWith, new Share(data.id!,data.name!,this.f.email.value)];
            this.f.email.setValue('');
          }else{
            this.translocoService.selectTranslateObject('project.register').subscribe(value => {
              this.messageService.clear();
              this.messageService.add({ key: 'toastRegisterProject', severity: 'error', summary: value.header, detail: value.equalEmail});
            }).unsubscribe();
          }
        },
        error: (error) =>{
          if(error.error === 'USE-404'){
            this.translocoService.selectTranslateObject('project.register').subscribe(value => {
              this.messageService.clear();
              this.messageService.add({ key: 'toastRegisterProject', severity: 'error', summary: value.header, detail: value.user404});
            }).unsubscribe();
          }else{
            this.translocoService.selectTranslateObject('project.register').subscribe(value => {
              this.messageService.clear();
              this.messageService.add({ key: 'toastRegisterProject', severity: 'error', summary: value.header, detail: error.error});
            }).unsubscribe();
          }
        }
      });
    } else {
      this.f.email.markAsDirty()
    }
  }

  removeShare(index: number) {
    this.project?.sharedWith.splice(index, 1);
  }

  closeModal() {
    this.resetForm();
    this.modalGoPage1();
    this.closeDialog.emit(this.projectID);
  }

  modalGoPage1() {
    this.modalpage = this.goPage(1);
    this.active = 0;
  }

  modalGoPage2() {
    this.checkInputs();
    if(this.form.valid) {
      if(this.editMode) {
        this.translocoService.selectTranslateObject('project.register.finishBtn').subscribe(value => {
          this.labelBtnImagePage = value;
        }).unsubscribe();
        this.modalpage = this.goPage(2);
        this.active = 1;
      } else {
        this.translocoService.selectTranslateObject('project.register.nextBtn').subscribe(value => {
          this.labelBtnImagePage = value;
        }).unsubscribe();
        this.modalpage = this.goPage(2);
        this.active = 1;
      }
    }
  }
  modalGoPage3() {
    this.modalpage = this.goPage(3);
    this.active = 2;
  }
  modalGoPage4() {
    if(this.editMode) {
      this.onSubmit();
    } else {
      this.modalpage = this.goPage(4);
      this.active = 3;
    }
  }

  goPage(index: number) {
    let aux = ['none', 'none', 'none', 'none'];
    aux[index-1] = '';
    return aux;
  }

  backPage(index: number) {
    this.modalpage = this.goPage(index);
    this.active = index-1;
  }

  FindUserVcus(userID: string) : Promise<boolean> {
    return new Promise((resolve) => {
      this.vcusOptions = [];
      const statusVcu = 'AC,AP';
      this.accountService.FindUserVcus(userID,statusVcu).subscribe(
        response =>{
          if(response)
            for(let element of response){
              if(element.id){
                let dto = new GenericEquipment(TypeEquipment.VCU, element.id, element.nickname!,'',element.serial!);
                this.vcusOptions.push(dto);
              }else{
                let dto = new GenericEquipment(TypeEquipment.VCU, '', element.nickname!,'',element.serial!);
                this.vcusOptions.push(dto);
              }
            }
          resolve(true);
        }
      );
    });
  }

  converterFormToCreateProjectPostReq(form: FormGroup): CreateProjectPostReq {
    const formValue = form.value;
    let project: CreateProjectPostReq = new CreateProjectPostReq();
    if(formValue.application.id){
      project.applicationID = formValue.application.id;
    }
    if(formValue.name){
      project.name = formValue.name;
    }
    if(formValue.favorite){
      project.default = formValue.favorite;
    }
    project.attributes = {};
    if (formValue.yearDate) {
      project.attributes['date-of-manufacture'] = formValue.yearDate;
    }
    if (formValue.yearDate) {
      project.attributes['detail'] = formValue.detail;
    }
    if (formValue.yearDate) {
      project.attributes['manufacturer'] = formValue.manufacturer;
    }
    if (formValue.yearDate) {
      project.attributes['vehicle-model'] = formValue.model;
    }

    if(this.selectedFiles && this.selectedFiles.length > 0){
      let fileName = '';
      this.selectedFiles.forEach(file => {
        fileName = fileName + file.name + ',';
      });
      fileName = fileName.substring(0, fileName.length - 1);
      project.attributes['photo'] = fileName;
    }

    project.tags = [];
    if (formValue.tags && Array.isArray(formValue.tags)) {
      project.tags = formValue.tags;
    }
    project.sharings = [];
    if (formValue.sharedWith && Array.isArray(formValue.sharedWith)) {
      for(let share of formValue.sharedWith){
        let addShare = new CreProUserSharingPostReq();
        addShare.userID = share.id;
        addShare.Permission = 'OR';
        project.sharings.push(addShare);
      }
    }

    project.vcus = [];
    project.equipmentIDs =[];
    if (formValue.genericEquipments && Array.isArray(formValue.genericEquipments)) {
      for (let equipment of formValue.genericEquipments) {
        if (equipment.vcu && equipment.vcu.serial) {
          let addVcu = new CreProVcuPostReq();
          addVcu.nickname = equipment.vcu.name;
          addVcu.serial = equipment.vcu.serial;
          project.vcus.push(addVcu);
        } else if(equipment.product && equipment.product.id) {
          project.equipmentIDs.push(Number(equipment.product.id));
        }
      }
    }
  
    return project;
  }

  converterFormToUpdateProjectPutReq(form: FormGroup): UpdateProjectPutReq {
    const formValue = form.value;
    let project: UpdateProjectPutReq = new UpdateProjectPutReq();
    if(formValue.application.id){
      project.applicationID = formValue.application.id;
    }
    if(formValue.name){
      project.name = formValue.name;
    }
    if(formValue.favorite){
      project.default = formValue.favorite;
    }
    project.attributes = {};
    if (formValue.yearDate) {
      project.attributes['date-of-manufacture'] = formValue.yearDate;
    }
    if (formValue.yearDate) {
      project.attributes['detail'] = formValue.detail;
    }
    if (formValue.yearDate) {
      project.attributes['manufacturer'] = formValue.manufacturer;
    }
    if (formValue.yearDate) {
      project.attributes['vehicle-model'] = formValue.model;
    }

    project.tags = [];
    if (formValue.tags && Array.isArray(formValue.tags)) {
      project.tags = formValue.tags;
    }
    project.sharings = [];
    if (formValue.sharedWith && Array.isArray(formValue.sharedWith)) {
      for(let share of formValue.sharedWith){
        let addShare = new UpdProUserSharingPutReq();
        addShare.userID = share.id;
        addShare.permission = 'OR';
        project.sharings.push(addShare);
      }
    }

    project.vcus = [];
    project.equipmentIDs =[];
    if (formValue.genericEquipments && Array.isArray(formValue.genericEquipments)) {
      for (let equipment of formValue.genericEquipments) {
        if (equipment.vcu && equipment.vcu.serial) {
          let addVcu = new CreProVcuPostReq();
          addVcu.nickname = equipment.vcu.name;
          addVcu.serial = equipment.vcu.serial;
          project.vcus.push(addVcu);
        } else if(equipment.product && equipment.product.id) {
          project.equipmentIDs.push(Number(equipment.product.id));
        }
      }
    }

    let fileName = '';
    if(this.selectedFiles){
      this.selectedFiles.forEach(file => {
        fileName = fileName + file.name + ',';
      });
    }
    if(this.project){
      this.project?.images.forEach(file => {
        fileName = fileName + file.name + ',';
      });
    }
    if(fileName.length > 0){
      fileName = fileName.substring(0, fileName.length - 1);
    }
    if(this.project?.primaryImage){
      project.attributes['primaryImage'] = this.project?.primaryImage?.name;
    }

    project.attributes['photo'] = fileName;;
    this.selectedFiles = null;

    return project;
  }

  onSelectFile(event: any) {
    if (event.files.length > 0) {
      this.selectedFiles = Array.from(event.files);
    }
  }

  uploadFiles() {
    let saveProjectID = 'tmp';
    let fileType = 'photo';
    if (this.selectedFiles !== null && this.selectedFiles.length > 0) {
      this.selectedFiles.forEach((file) => {
        let fileName = file.name;
        //TODO: revisar o fluxo de arquivos acima de 10 MB
        if (file.size > 10 * 1024 * 1024){
          this.fileService.HeadFile(this.userID, saveProjectID, 'photo', fileName).subscribe(
            (response: HttpResponse<any>) => {
              const uploadId = response.headers.get('upload_id');
              if (uploadId) {
                const partNumber: number = 1;
                this.fileService.PostFile(this.userID, saveProjectID, 'photo', fileName, uploadId, partNumber, file).subscribe(
                  (response: HttpResponse<any>) => {
                    const etagResponse = response.body;
                    if (etagResponse && etagResponse.ETag && etagResponse.PartNumber) {
                      let partsArray: { ETag: string, PartNumber: number }[] = [];
                      partsArray.push({ETag: etagResponse.ETag,PartNumber: etagResponse.PartNumber});
                      this.fileService.PutFile(this.userID, saveProjectID, 'photo', fileName, uploadId, partsArray).subscribe(
                        (putResponse: HttpResponse<any>) => {
                        },
                        error => {
                          console.error('uploadFiles:::PutFile:::error > ', error);
                        }
                      );
                    }
                  },
                  error => {
                    console.error('uploadFiles:::PostFile:::error > ', error);
                  }
                );
              }
            },
            error => {
              console.error('uploadFiles:::HeadFile:::error > ', error);
            }
          );
        }else{
          this.translocoService.selectTranslateObject('project.register.upload.message').subscribe(value => {
            this.fileService.PutFileSimple(this.userID, saveProjectID, fileType, file).subscribe({
              next: _=>{
                this.messageService.clear();
                this.messageService.add({ key: 'toastRegisterProject', severity: 'success', summary:value.header, detail: value.success});
              },
              error: _=>{
                this.messageService.clear();
                this.messageService.add({ key: 'toastRegisterProject', severity: 'success', summary:value.header, detail: value.error});                
              }
            });  
          });
        }
      });
    } else {
      console.error('Nenhum arquivo selecionado.');
    }
  }

  findCategories() : Promise<boolean> {
    return new Promise((resolve) => {
      let language = localStorage.getItem('language');
      if(!language){
        language = 'pt-br'
      }
      this.categoryService.FindCategories(language).subscribe(
        data =>{
          if(data)
            data.forEach( register =>{
              this.categoriesOptions.push({ 
                name: register.categoryName! 
                , category: ''
                , id: register.categoryID?.toString()!
                , type: TypeEquipment.EQUIPMENT
                ,serial:'' })
            });
          resolve(true)
        });
    });
  }

  FindEquipments(categoryID:string, index: number){
    let language = localStorage.getItem('language');
    if(!language){
      language = 'pt-br'
    }

    if (!this.productsOptions[index]) {
      this.productsOptions[index] = [];
    } else {
      this.productsOptions[index] = [];
    }

    this.categoryService.FindEquipments(language, Number(categoryID)).subscribe(data => {
      const options = data.map(register => new GenericEquipment(
        TypeEquipment.EQUIPMENT,
        register.equipmentID?.toString()!,
        register.equipmentName!,
        register.categoryName ?? '',
        ''
      ));
      this.productsOptions[index] = options;
    });
  }

  loadEquipment(project: Project){
    const formArray = this.form.get('genericEquipments') as FormArray;

    project.vcus.forEach((vcu: Vcu) => {
      formArray.push(this.formBuilder.group({
        vcu: [new GenericEquipment(TypeEquipment.VCU, vcu.id, vcu.surname, '', vcu.serialNumber), CustomValidator.getRequired()]
      }))
    });

    project.equipments.forEach((equipment: Equipment) => {
      this.FindEquipments(equipment.categoryID+'', formArray.length);
      formArray.push(this.formBuilder.group({
        category: [
          new GenericEquipment(TypeEquipment.EQUIPMENT, equipment.categoryID+'', equipment.categoryName, '', ''),
          CustomValidator.getRequired()],
        product: [
          new GenericEquipment(TypeEquipment.EQUIPMENT, equipment.id+'', equipment.name, equipment.categoryName, ''),
          CustomValidator.getRequired()]
      }));
    });
  }

  get f() { return this.form.controls; }
}

export enum TypeEquipment {
  VCU = 'vcu',
  EQUIPMENT = 'equipment'
}

export class GenericEquipment {
  id: string;
  type: TypeEquipment;
  name: string;
  category: string;
  serial: string;
  
  constructor(type: TypeEquipment, 
      id: string = '', 
      name: string = '', 
      category: string = '',
      serial: string = '') {
    this.id = id;
    this.type = type;
    this.name = name;
    this.category = category;
    this.serial = serial;
  }
}