
export class InitiateAuthResponse {
    AuthenticationResult?: {
        AccessToken?: string,
        ExpiresIn?: number,
        IdToken?: string,
        NewDeviceMetadata?: { 
           DeviceGroupKey?: string,
           DeviceKey?: string
        },
        RefreshToken?: string,
        TokenType?: string
    };
    ChallengeName?: string = '';
    ChallengeParameters?: {[key: string]: String};
   Session?: string = '';
}