import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Account } from '../model/form/account';
import { CognitoService } from './cognito.service';
import { User } from '../model/user';
import { CognitoException } from '../model/enum/cognito-exception';
import { DocType } from '../model/doc-type';
import { LanguageEnum } from '../model/enum/language-enum';
import { repeat } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User = new User();

  constructor(private router: Router, private cognitoService: CognitoService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    
    // return true;

    let token = sessionStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');

    if(token) {
      return await this.verifyToken(token).then(valid => {
        if(valid) {
          // if(refreshToken) {
          //   return this.refreshAuth(refreshToken).then(status => {
          //     if(CognitoException.SUCCESS === status) {
          //       return true;
          //     } else {
          //       return !this.logout();
          //     }
          //   });
          // } else {
          //   return !this.logout();
          // }
          return true;
        } else {
          return !this.logout();
        }
      });
    } else {
      return !this.logout();
    }
  }
  
  async signUp(account: Account, language: string | null) {
    account.email.trim();
    if(account.docNumber){
      account.docNumber = this.removeMask(account.docNumber);
      account.docType = (account.docNumber.length === 11 ? DocType.CPF : DocType.CNPJ);
    }else{
      account.docNumber = '0'.repeat(11);
      account.docType = DocType.CPF;      
    }
    account.language = (language === null ? LanguageEnum.EN_US : language);
    return this.cognitoService.signUp(account);
  }

  async confirmSignUp(email: string, code: string) {
    this.user = new User();
    this.user.email = email.trim();
    this.user.code = code.trim();
    return this.cognitoService.confirmSignup(this.user);
  }
  
  async initiateAuth(email: string, password: string) : Promise<boolean> {
    this.user = new User();
    this.user.email = email.trim();
    this.user.password = password;
    return this.cognitoService.initiateAuth(this.user);
  }

  async refreshAuth(refreshToken: string) : Promise<string> {
    let username = localStorage.getItem('nameUser');
    let token = sessionStorage.getItem('token');
    this.user = new User();
    this.user.refreshToken = refreshToken;
    if(username) {
      this.user.usernameAWS = username;
    } else if(token){
      await this.getUser(token);
      let username = localStorage.getItem('nameUser');
      if(username)
        this.user.usernameAWS = username;
    }
    return this.cognitoService.refreshAuth(this.user).then(token => {
      sessionStorage.setItem('token', token);
      return CognitoException.SUCCESS;
    }).catch(error => error);
  }

  async forgotPassword(email: string, name: string | null, language: string | null) : Promise<any> {
    this.user = new User();
    this.user.email = email.trim();
    if(!name) {
      if(LanguageEnum.EN_US === language)
        this.user.name = 'User';
      else
        this.user.name = 'Usuário';
    } else {
      this.user.name = name;
    }
    this.user.language = (language ? language : LanguageEnum.PT_BR);
    return this.cognitoService.forgotPassword(this.user);
  }

  async confirmForgotPassword(email: string, password: string, code: string) : Promise<any> {
    this.user = new User();
    this.user.email = email.trim();
    this.user.password = password;
    this.user.code = code.trim();
    return this.cognitoService.confirmForgotPassword(this.user);
  }

  async resendConfirmationCode(email: string, name: string | null, language: string | null, origin: string | null) : Promise<any> {
    this.user = new User();
    this.user.email = email.trim();
    if(!name) {
      if(LanguageEnum.EN_US === language)
        this.user.name = 'User';
      else
        this.user.name = 'Usuário';
    } else {
      this.user.name = name;
    }
    this.user.language = (language ? language : LanguageEnum.PT_BR);
    origin = (origin ? origin : 'create');
    return this.cognitoService.resendConfirmationCode(this.user, origin);
  }

  async getUser(token: string) {
    return this.cognitoService.getUser(token);
  }

  async verifyToken(token: string) : Promise<boolean> {
    return this.cognitoService.verifyToken(token);
  }

  async revokeToken(token: string) {
    this.user = new User();
    this.user.token = token;
    this.cognitoService.revokeAuth(this.user);
  }

  async logout() : Promise<boolean> {
    let token = localStorage.getItem('refreshToken');
    if(token) {
      await this.revokeToken(token);
    }
    sessionStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email-activate-account');
    localStorage.removeItem('userID');
    localStorage.removeItem('idUser');
    localStorage.removeItem('nameUser');
    localStorage.removeItem('language');
    this.router.navigate(['/login']);    
    return true;
  }

  private removeMask(str: string) : string {
    str = str.replaceAll(".","");
    str = str.replaceAll("-","");
    str = str.replaceAll("/","");
    return str;
  }
}


export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(AuthService).canActivate(next, state);
}