import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { FindCategoriesGetResp } from '../model/response/find-categories-get-resp';
import { FindEquipmentsGetResp } from '../model/response/find-equipments-get-resp';

@Injectable({providedIn: 'root'})
export class CategoryService {
  fileUrl: string;

  constructor(private http: HttpClient) {
    this.fileUrl = enviroment.BASE_URL_API + '/global/category';
  }

  FindCategories(language:string): Observable<FindCategoriesGetResp[]> {
    const url = `${this.fileUrl}?Language=${language}`;
    return this.http.get<FindCategoriesGetResp[]>(url);
  }

  FindEquipments(language:string,categoryID: number): Observable<FindEquipmentsGetResp[]> {
    const url = `${this.fileUrl}/${categoryID}/equipament?Language=${language}`;
    return this.http.get<FindEquipmentsGetResp[]>(url);
  }

}
