import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { Table } from 'primeng/table';
import { Status } from 'src/app/model/enum/status';
import { Project } from 'src/app/model/form/project';
import { Vcu } from 'src/app/model/form/vcu';
import { LogVCU } from 'src/app/model/log-vcu';
import { MapVCU } from 'src/app/model/map-vcu';
import { Item } from 'src/app/model/Item';
import { VcuService } from 'src/app/service/vcu.service';
import { ProjectService } from 'src/app/service/project.service';
import { ActivatedRoute } from '@angular/router';
import { VcuAttributes } from 'src/app/model/vcu-attributes';
import { Location } from '@angular/common';
import { Load } from 'src/app/model/mockup/load-mockup';
import { Image } from 'src/app/model/Image';
import { ModelService } from 'src/app/service/model.service';

@Component({
  selector: 'app-vcu',
  templateUrl: './vcu.component.html',
  styleUrl: './vcu.component.css'
})
export class VcuComponent implements OnInit {

  logs: LogVCU[] = [];
  maps: MapVCU[] = [];
  vcu!: Vcu;
  projects: Project[];

  @ViewChild('dtLog') dtLog: Table | undefined;
  @ViewChild('dtMap') dtMap: Table | undefined;
  loadingLog: boolean = true;
  loadingMap: boolean = true;
  imgClass: string = '';
  cardHeaderClass: string = '';
  subtitleClass: string = '';
  statusClass: string = '';
  statusName: string = '';
  isPending: boolean = false;
  backupLog: LogVCU | null = null;
  backupMap: MapVCU | null = null;
  showDialogUnlink: boolean = false;
  showEditVcuDialog: boolean = false;
  reasonUnlinkVcu: string = '';
  projectOptions: Item[] = [];
  showDialogLink: boolean = false;
  projectLink: Item | null = null;
  projectLinkInvalid: string = '';
  projectUnlinkInvalid: string = '';
  showProjectDialog: boolean = false;
  unlinkVcuBtnLoading: boolean = false;
  token: string | null = '';
  userID: string = '';
  vcuID: string = '';
  messages: Message[] = [];
  vcuProjects: Item[] = [];
  isLoadingProjects: boolean = false;
  modelOptions: Item[] = [];
  idAux: number = 0;
  isDisabled: boolean = false;

  constructor(private translocoService: TranslocoService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private titleService: Title,
    private projectService: ProjectService,
    private vcuService: VcuService,
    private location: Location,
    private modelService: ModelService) {

    this.vcu = new Vcu();
    //TODO BACKEND solciitar os logs por usuario e id da VCU
    // this.logs = VCU_LOG_LIST;
    //TODO BACKEND solciitar os mapas por usuario e id da VCU
    // this.maps = VCU_MAP_LIST;
    //TODO BACKEND solciitar os projetos por usuario e id da VCU
    this.projects = [];
    this.loadingLog = false;
    this.loadingMap = false;
    this.isLoadingProjects = false;
    //TODO BACKEND solicitar a lista de manuais e documentos da VCU
  }
  async ngOnInit() {
    this.userID = localStorage.getItem('idUser') ?? '';
    this.changeLanguage();
    this.setTitle();
    this.messageService.clear();
    this.modelService.getAllModel().subscribe(data => {
      this.modelOptions = ModelService.toItemList(data);
    })
    this.getVcu();
  }

  getVcu() {
    this.activatedRoute.params.subscribe(params => {
      this.vcuID = decodeURIComponent(params['id']);
      this.vcuService.setVcuID(this.vcuID);
      this.isLoadingProjects = true;
      this.vcuService.getVcu().subscribe({ 
        next: (response) => {
          if(response && response.length > 0) {
            this.vcu = VcuService.userVcuToVcu(response[0]);
            if(this.vcu.vcuAttributes?.model) {
                let nameModel = this.modelOptions.filter(model => model.id === this.vcu.vcuAttributes?.model);
                if(nameModel.length > 0) {
                  this.vcu.model = new Item(this.vcu.vcuAttributes?.model, nameModel[0].name);
                }
            }
            this.setTitle(this.vcu.surname);
            console.log('### VCU ###', this.vcu)
            this.vcu.status === Status.DISABLED ? this.isDisabled = true : this.isDisabled = false;
            this.vcuStatusVerify();
            this.removeProjectsDI();
            this.isLoadingProjects = false;
          }
        },
        error: _ => {
          this.isLoadingProjects = false;
        }
      });
    });
  }

  vcuStatusVerify() {
    if(this.vcu !== undefined) {
      if(this.vcu.status == Status.ACTIVE){
        this.statusClass = 'status-green';
        this.translocoService.selectTranslate('vcu.status.ac').subscribe(value => 
          this.statusName = value
        ).unsubscribe();
      } else if(this.vcu.status == Status.ACTIVATION_PENDING){
        this.imgClass = 'img-pending'
        this.cardHeaderClass = 'card-header-pending';
        //this.subtitleClass = 'subtitle-pending';
        //this.isPending = true;
        this.statusClass = 'status-yellow';
        this.translocoService.selectTranslate('vcu.status.ap').subscribe(value => 
          this.statusName = value
        ).unsubscribe();
      } else if(this.vcu.status == Status.DISABLED){
        this.statusClass = 'status-red';
        this.translocoService.selectTranslate('vcu.status.di').subscribe(value => 
          this.statusName = value
        ).unsubscribe();
      }
      this.idAux += 1;
      this.vcuService.vcuImage(this.idAux, this.vcu).subscribe(img => {
        if (this.vcu) {
          this.vcu.image = img;
        }
      });
    }
  }

  removeProjectsDI() {
    this.vcu.projects = this.vcu.projects.filter(proj => proj.status !== Status.DISABLED);
  }

  formatImage(image: string | undefined | null) {
    if(image)
      return 'url('+image+')';
    return ''
  }

  editVCU() {
    this.showEditVcuDialog = true;
  }

  closeVcuDialog() {
    this.showEditVcuDialog = false;
    this.getVcu();
    // this.vcuService.getVcu().subscribe(response => {
    //   if(response && response.length > 0) {
    //     this.vcu = VcuService.userVcuToVcu(response[0]);
    //     // if(this.vcu.vcuAttributes?.model === '4D5' || this.vcu.vcuAttributes?.model === '2B1') {
    //     //   this.vcu.image = Load.IMAGE_FT700_PLUS;
    //     // } else {
    //     //   this.vcu.image = Load.IMAGE_FT700;
    //     // }
    //     // console.log('MODEL?', this.vcu.vcuAttributes?.model);
    //     // console.log('PATH?', 'ft_models/' + (this.vcu.vcuAttributes?.model ?? 'default') + '/ft.png');
    //     let imageVCU = new Image(0, 'FT', 'ft_models/' + (this.vcu.vcuAttributes?.model ?? 'default') + '/ft.png');
    //     this.vcu.image = imageVCU;
    //   }
    // });
  }

  dialogUnlink() {
    this.projectUnlinkInvalid = '';
    this.messages = []
    this.showDialogUnlink = true;
  }

  deleteVcu() {
    this.translocoService.selectTranslateObject('vcu.remove').subscribe(value => 
      this.confirmationService.confirm({
        message: value.message,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          this.vcu.status = Status.DISABLED;
            this.vcuService.deleteVcuNoProject().subscribe({
              next: _ => {
                this.getVcu();
                this.translocoService.selectTranslate('vcu.remove.success').subscribe(value => {
                  this.messageService.add({ key: 'toastVcu', severity: 'success', summary: value});
                }).unsubscribe();
              },
              error: _ => {
                this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
                  { severity: 'error', summary: value}
                ]);
              }
            });
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  unlinkVCU() {
    if(this.reasonUnlinkVcu && this.reasonUnlinkVcu !== '') {
      if(!this.vcu.vcuAttributes) {
        this.vcu.vcuAttributes = new VcuAttributes();
      }
      this.unlinkVcuBtnLoading = true;
      this.vcu.vcuAttributes.reasonUnlink = this.reasonUnlinkVcu;
      this.vcuService.updateVcu(this.vcu).subscribe(updated => {
        this.vcuService.unlinkVcuInProject().subscribe({
          next: _ => {
            this.messages = []
            this.messageService.clear();
            this.translocoService.selectTranslateObject('vcu.detail.unlinkVcu').subscribe(value => {
              this.messageService.add({ key: 'toastVcu', severity: 'success', summary: value.header, detail: value.msgSuccess});
            }).unsubscribe();
            setTimeout(() => {
              this.unlinkVcuBtnLoading = false;
              this.showDialogUnlink = false;
              this.location.back();
            }, 3000);  //3s
          },
          error: _ => {
            this.messages = []
            this.unlinkVcuBtnLoading = false;
            this.translocoService.selectTranslate('errorConection').subscribe(value => 
              this.messages = [{ severity: 'error', summary: value}]
            ).unsubscribe();
          }
        });
      });
    } else {
      this.projectUnlinkInvalid = 'ng-dirty ng-invalid';
      this.translocoService.selectTranslate('inputRequired').subscribe(value => 
        this.messages = [{ severity: 'error', summary: value }]
      ).unsubscribe();
    }
  }

  refreshListProjects(){
    this.getVcu();
  }

  linkToProject() {
    //TODO BACKEND vincular VCU ao Projeto
    this.userID = localStorage.getItem('idUser') ?? '';
    // todos os projetos
    this.projectService.findAllProjectSummary(this.userID,'AC').subscribe(data => {
      let projOptions = []
      let projects: Project[] = this.projectService.projectSummaryToProject(data);
      // remove os projetos da vcu de todos os projetos
      if(this.vcu.projects) {
        for(let project of projects) {
          if(this.vcu.projects.filter(proj => proj.id === project.id).length === 0) {
            projOptions.push(new Item(project.id, project.name));
          }
        }
        this.projectOptions = [...projOptions]
        if(this.projectOptions.length > 0) 
          this.projectLink = this.projectOptions[0];
        this.showDialogLink = true;
      }
    });
  }

  linkVCU() {
    //TODO BACKEND vincular VCU ao Projeto this.form.value -> garageLink.id
    if(this.vcu && this.projectLink) {
      this.vcuService.setVcuID(this.vcu.id);
      this.vcuService.setProjectID(this.projectLink.id+'');
      this.vcuService.linkVcuInProject().subscribe({
        next: _ => {
          this.messageService.clear();
          this.translocoService.selectTranslateObject('vcu.detail.linkVcu').subscribe(value => {
            this.messageService.add({ key: 'toastVcu', severity: 'success', summary: value.header, detail: value.msgSuccess});
          }).unsubscribe();
          this.showDialogLink = false;
        }, error: _ => {
          this.messages = []
          this.translocoService.selectTranslate('errorConection').subscribe(value => 
            this.messages = [{ severity: 'error', summary: value}]
          ).unsubscribe();
        }
      });
    } else {
      this.projectLinkInvalid = 'ng-dirty ng-invalid';
    }
  }
  
  newProject() {
    //TODO Chamar cadastro do projeto
    this.showProjectDialog = true;
  }

  updateProjectDialog(eventProjectID: string) {
    if(eventProjectID) {
      this.vcuService.setVcuID(this.vcuID)
      this.vcuService.setProjectID(eventProjectID)
      this.vcuService.linkVcuInProject().subscribe();
    }
    this.showProjectDialog = false;
  }
  
  onRowEditInitLog(log: LogVCU) {
    this.backupLog = { ...log };
  }

  onRowEditSaveLog(log: LogVCU) {
    //TODO chamar backend e atualizar o log editado
  }

  onRowEditCancelLog(index: number) {
    if(this.backupLog)
      this.logs[index] = { ...this.backupLog};
  }

  onRowRemoveLog(event: Event, index: number) {
    this.translocoService.selectTranslateObject('vcu.detail.tableLog.delete').subscribe(value => 
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: value.message,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          //TODO chamar backend para remover o Log
          this.logs.splice(index, 1);
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  onRowEditInitMap(map: MapVCU) {
    this.backupMap = { ...map };
  }

  onRowEditSaveMap(map: MapVCU) {
    //TODO chamar backend e atualizar o Map editado
  }

  onRowEditCancelMap(index: number) {
    if(this.backupMap)
      this.maps[index] = { ...this.backupMap};
  }

  onRowRemoveMap(event: Event, index: number) {
    
    this.translocoService.selectTranslateObject('vcu.detail.tableMap.delete').subscribe(value => 
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: value.message,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          //TODO chamar backend para remover o Map
          this.maps.splice(index, 1);
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  applyFilterGlobalLog($event: any, stringVal: string) {
    this.dtLog?.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  applyFilterGlobalMap($event: any, stringVal: string) {
    this.dtMap?.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  setTitle(title?: string) {
    if(title) {
      this.titleService.setTitle('FTCloud - ' + title);
    } else {
      this.translocoService.selectTranslate('title.vcu').subscribe(value => 
        this.titleService.setTitle(value)
      );
    }
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }
}