export enum CognitoException {
    SUCCESS = "Success",
    NOT_AUTHORIZED = "NotAuthorizedException",
    USER_NOT_CONFIRMED = "UserNotConfirmedException",
    NETWORKING_ERROR = "NetworkingError",
    USERNAME_EXISTS = "UsernameExistsException",
    INVALID_PASSWORD = "InvalidPasswordException",
    CODE_MISMATCH = "CodeMismatchException",
    EXPIRED_CODE = "ExpiredCodeException",
    USER_NOT_FOUND = "UserNotFoundException",
    LAMBDA_VALIDATION = "UserLambdaValidationException"
}
