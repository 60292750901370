export class Equipment {
    id: number;
    name: string;
    categoryID: number;
    categoryName: string;

    constructor(
        id = 0,
        name = '',
        categoryID = 0,
        categoryName = ''
    ) {
        this.id = id;
        this.name = name;
        this.categoryID = categoryID;
        this.categoryName = categoryName;
    }
}
