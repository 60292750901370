
<div class="background">
    <div class="container">
        <div class="left-box">
            <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                <!-- {{ form.value | json }} -->
                <div class="logo-red"></div>
                <input class="email" 
                    type="email"
                    id="email" name="email"
                    formControlName="email" 
                    placeholder="{{ 'user.login.email' | transloco }}" 
                    pInputText />
                <p-password class="ig-pass" 
                    id="password" name="password"
                    formControlName="password"
                    placeholder="{{ 'user.login.password' | transloco }}"
                    [feedback]="false"
                    [toggleMask]="true" 
                    [style]="{'width':'100%'}"
                    [inputStyle]="{'width':'100%'}"/>
                <p-messages 
                    [(value)]="messages" 
                    [enableService]="false" 
                    [style]="{'font-size':'15px'}"/>
                <div class="options">
                    <!-- <div class="circles">
                        <div class="circle eua"></div>
                        <div class="circle br"></div>
                    </div> -->
                    <p-selectButton 
                        name="language"
                        formControlName="language" 
                        [options]="languageOptions" 
                        optionLabel="name"
                        optionDisabled="disabled"
                        (click)="fixLanguage()">
                        <ng-template let-item pTemplate>
                            <div [class]="item.class"></div>
                        </ng-template>
                    </p-selectButton>
                    <div class="others">
                        <a [routerLink]="[]" (click)="forgotPassword()">{{'user.login.forgotPassword' | transloco}}</a>
                        <p-button 
                            type="submit"
                            severity="danger"
                            [raised]="true"
                            [loading]="submitBtnLoading"
                            label="{{'user.login.signIn' | transloco}}">
                        </p-button>
                    </div>
                </div>
                <div class="options-small">
                    <button 
                        pButton 
                        type="submit"
                        severity="danger"
                        [raised]="true"
                        [loading]="submitBtnLoading"
                        label="{{'user.login.signIn' | transloco}}">
                    </button>
                    <div class="others">
                        <!-- <div class="circles">
                            <div class="circle eua"></div>
                            <div class="circle br"></div>
                        </div> -->
                        <p-selectButton 
                            name="language"
                            formControlName="language" 
                            [options]="languageOptions" 
                            optionLabel="name"
                            optionDisabled="disabled"
                            (click)="fixLanguage()">
                            <ng-template let-item pTemplate>
                                <div [class]="item.class"></div>
                            </ng-template>
                        </p-selectButton>
                        <a [routerLink]="[]" (click)="forgotPassword()">{{'user.login.forgotPassword' | transloco}}</a>
                    </div>
                </div>
                <div class="message">
                    <i class="pi pi-info-circle"></i>
                    <p>{{'user.login.dontAccount' | transloco}}</p>
                    <a [routerLink]="[]" (click)="signUp()">{{'user.login.signUp' | transloco}}</a>
                </div>
                <div class="message-small">
                    <i class="pi pi-info-circle"></i>
                    <div class="ask">
                        <p>{{'user.login.dontAccount' | transloco}}</p>
                        <a [routerLink]="[]" (click)="signUp()">{{'user.login.signUp' | transloco}}</a>
                    </div>
                </div>
            </form>
        </div>
        <div class="right-box"></div>
    </div>
</div>
