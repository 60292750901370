export class Reason {
    reasonId: number;
    internationalizationId: string;
    description: string;

    constructor(reasonId: number = 0, internationalizationId: string = '', description: string = '') {
        this.reasonId = reasonId;
        this.internationalizationId = internationalizationId;
        this.description = description;
    }
}