import { TrashTypeEnum } from "./enum/trash-type";
export class Trash {
  id: string = '';
  name: string = '';
  type: string = '';
  deletionDate: string = '';

  constructor(data: Partial<Trash>) {
      this.id = data.id || '';
      this.name = data.name || '';
      this.type = TrashTypeEnum[data.type as unknown as number] || ''; 

      this.deletionDate = data.deletionDate 
          ? this.adjustDateToISOString(data.deletionDate) 
          : '';
  }

  private adjustDateToISOString(dateString: string): string {
      const date = new Date(dateString); 
      date.setHours(date.getHours() - 3); 
      return date.toISOString();
  }

  static fromArray(dataArray: any[]): Trash[] {
      return dataArray.map(data => new Trash(data));
  }
}
