import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { LanguageEnum } from '../model/enum/language-enum';
import { Application } from '../model/application';
import { ApplicationResponse } from '../model/response/application-response';
import { Item } from '../model/Item';

@Injectable({providedIn: 'root'})
export class ApplicationService {

  httpOptions: {};
  applicationUrl: string;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': 'Bearer ' + idToken;
      })
    };
    this.applicationUrl = enviroment.BASE_URL_API + '/global/application_type';
  }

  getAllApplication() : Observable<ApplicationResponse[]> {
    let lang = this.getLanguage(localStorage.getItem('language'));
    let idToken = localStorage.getItem('idToken');

    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + idToken
      })
    };
    let url = this.applicationUrl + this.getLanguageUrl(lang);

    return this.http.get<ApplicationResponse[]>(url, this.httpOptions).pipe(
      tap()
    );
  }

  toApplication(application: ApplicationResponse) : Application {
    if(application) {
      return new Application(
            application.applicationTypeId,
            application.internationalizationId,
            application.applicationTypeDescription,
            new Date(application.applicationTypeCreatedDate));
    } else {
      return new Application();
    }
  }

  toApplicationList(ctrs: ApplicationResponse[]) : Application[] {
    if(ctrs) {
      let applications: Application[] = [];
      ctrs.forEach(ct => applications.push(this.toApplication(ct)));
      return applications;
    } else {
      return [];
    }
  }

  static toItemList(apps: ApplicationResponse[]) : Item[] {
    if(apps) {
      let applications: Item[] = [];
      apps.forEach(ap => applications.push(new Item(ap.applicationTypeId, ap.applicationTypeDescription)));
      return applications;
    } else {
      return [];
    }
  }

  private getLanguageUrl(language: string) {
      return '?Language=' + language;
  }

  private getLanguage(language: string | null) {
    console.log('language',language)
      return (language ? language : LanguageEnum.PT_BR);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
