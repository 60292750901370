export class Application {
    
    id?: number;
    internationalizationId?: string;
    description?: string;
    createdDate?: Date;

    constructor(
        id?: number,
        internationalizationId?: string,
        description?: string,
        createdDate?: Date
    ) {
        this.id = id;
        this.internationalizationId = internationalizationId;
        this.description = description;
        this.createdDate = createdDate;
    }
}