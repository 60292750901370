<app-dashboard-template>
    <div class="v-card-img">
        <div class="v-img" [ngClass]="imgClass"  [ngStyle]="{'background-image': formatImage(vcu.image?.href) }"></div> <!-- img-pending -->
    </div>
    <div class="v-container">
        <!-- <div class="v-card-vcu">
            <div class="v-card-container">
                <div class="v-card-header" [ngClass]="cardHeaderClass"></div>
            </div>
        </div> -->
        <div class="overlay" *ngIf="isLoadingProjects">
          <p-progressSpinner styleClass="large-spinner"></p-progressSpinner>
        </div>
        <p-confirmDialog />
        <div class="v-card">
            <div class="v-row">
                <div class="v-left">
                    <div class="v-card-header" [ngClass]="cardHeaderClass">
                        <div class="v-title">
                            <h1>{{ vcu.surname }}</h1>
                            <h3 class="v-subtitle" [ngClass]="subtitleClass">
                                {{ 'vcu.card.pendingActivate' | transloco }} (!)
                            </h3>
                            <div *ngIf="!isPending" class="v-row">
                                <p  [ngClass]="statusClass">
                                    <b>{{ 'vcu.card.status' | transloco }}: </b>{{ statusName }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-right">
                    <div class="v-row">
                        <p-button class="shared" icon="pi pi-pencil" [disabled]="isDisabled" (click)="editVCU()" />
                        <p-button class="shared" icon="pi pi-trash" [disabled]="isPending || isDisabled" (click)="deleteVcu()" />
                    </div>
                </div>
            </div>
        </div>
        <p-accordion [multiple]="true" expandIcon="pi pi-fw pi-chevron-up" [activeIndex]="[0]">
            <p-accordionTab header="{{ 'vcu.detail.basicData' | transloco }}" iconPos="end">
                <div class="v-card-body">
                    <div class="v-left">
                        <p><b>{{ 'vcu.card.generalVersion' | transloco }}: </b>{{ vcu.generalVersionDate | date: 'dd/MM/yyyy' }}</p>
                        <p><b>{{ 'vcu.card.serial' | transloco }}: </b>{{ vcu.serialNumber}}</p>
                        <div class="v-row">
                            <p><b>{{ 'vcu.card.lastAcess' | transloco }}: </b>{{ vcu.lastAcessDate | date: 'dd/MM/yyyy' }}</p>
                        </div>
                    </div>
                    <div class="v-right-vcu">
                        <p><b>{{ 'vcu.tags' | transloco }}: </b></p>
                        <ul>
                            <li *ngFor="let tag of vcu.tags">
                                <p-tag value="{{tag}}" severity="danger" />
                            </li>
                        </ul>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="{{ 'vcu.detail.project' | transloco }}" iconPos="end">
                <div class="centered-spinner" *ngIf="isLoadingProjects">
                    <p-progressSpinner styleClass="large-spinner"></p-progressSpinner>
                </div>
                <div *ngIf="!isLoadingProjects">
                    <div class="cards-project" *ngFor="let project of vcu.projects">
                        <app-card-project [project]="project" [user]="userID" (refreshDashboard)="refreshListProjects()"></app-card-project>
                    </div>
                </div>
                <div *ngIf="!isPending" class="cards">
                  <div class="card-plus">
                    <!-- TODO criar Dialog para vincular a VCU no projeto -->
                    <a [routerLink]="[]" (click)="linkToProject()">{{ 'vcu.detail.tabProject.link' | transloco }}</a>
                  </div>
                  <div class="card-plus">
                    <a [routerLink]="[]" (click)="newProject()">+ {{ 'vcu.detail.tabProject.new' | transloco }}</a>
                  </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="{{ 'vcu.detail.doc' | transloco }}" iconPos="end">
                <div class="content-docs">
                    <ul>
                        <li><a [routerLink]="[]">Manual W8-O2 Nano</a></li>
                        <li><a [routerLink]="[]">Manual Nano PRO</a></li>
                        <li><a [routerLink]="[]">Manual Air Fuel Meter</a></li>
                        <li><a [routerLink]="[]">Manual W8-O2 Meter Slim</a></li>
                        <li><a [routerLink]="[]">Manual ETM-1 FuelTech</a></li>
                        <li><a [routerLink]="[]">Manual Sensor de Pressão PS-10B</a></li>
                    </ul>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
    <p-dialog header="{{ 'vcu.detail.unlinkVcu.header' | transloco }}" [modal]="true" [(visible)]="showDialogUnlink" [style]="{ width: '25rem' }">
        <span class="dialog-msg">{{ 'vcu.detail.unlinkVcu.msg' | transloco }}</span>
        <div class="v-row">
            <textarea 
              id="projectLink"
              name="projectLink"
              rows="3" 
              cols="20" 
              placeholder="{{ 'vcu.detail.unlinkVcu.reason' | transloco }}"
              [(ngModel)]="reasonUnlinkVcu"
              [ngClass]="projectUnlinkInvalid"
              (onChange)="projectUnlinkInvalid = ''"
              pInputTextarea
              [style]="{'width':'100%', 'margin-bottom':'30px'}" >
            </textarea>
        </div>
        <div class="v-row">
            <p-messages id="messages"
                [(value)]="messages" 
                [enableService]="false" 
                [style]="{'font-size':'15px'}"/>
        </div>
        <div class="dialog-btns">
            <p-button id="dialog-cancel" label="{{ 'vcu.detail.unlinkVcu.cancel' | transloco }}" severity="secondary" (onClick)="showDialogUnlink = false" />
            <p-button id="dialog-save" label="{{ 'vcu.detail.unlinkVcu.save' | transloco }}" [loading]="unlinkVcuBtnLoading" (onClick)="unlinkVCU()" />
        </div>
    </p-dialog>
    <p-dialog 
        id="linkVcu" 
        header="{{ 'vcu.detail.linkVcu.header' | transloco }}" 
        [modal]="true" 
        [(visible)]="showDialogLink" 
        [style]="{ 'width': '350px', 'height': '270px' }"
    >
        <span style="margin-bottom: 10px;">{{ 'vcu.detail.linkVcu.msg' | transloco }}:</span>
        <div class="v-row">
            <p-dropdown id="projectLink"
                [options]="projectOptions" 
                [(ngModel)]="projectLink"
                name="projectLink"
                optionLabel="name"  
                appendTo="body"
                placeholder="{{ 'vcu.detail.linkVcu.project' | transloco }}"
                emptyMessage="{{ 'noResult' | transloco }}"
                [ngClass]="projectLinkInvalid"
                (onChange)="projectLinkInvalid = ''"
                [style]="{ 'width': '100%', 'margin-top': '10px' }"/>
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 35px;">
            <p-button 
                id="dialog-cancel" 
                label="{{ 'vcu.detail.linkVcu.cancel' | transloco }}" 
                severity="secondary" 
                (onClick)="showDialogLink = false" />
            <p-button 
                id="dialog-save" 
                label="{{ 'vcu.detail.linkVcu.save' | transloco }}" 
                (onClick)="linkVCU()"
                [style]="{ 'margin-left': '20px' }" />
        </div>
    </p-dialog>
    <p-toast key="toastVcu" />
    <app-register-vcu [showDialog]="showEditVcuDialog" (closeDialog)="closeVcuDialog()" [vcu]="vcu" [editMode]="true"></app-register-vcu>
    <app-register-project [showDialog]="showProjectDialog" (closeDialog)="updateProjectDialog($event)"></app-register-project>
</app-dashboard-template>