import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { enviroment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class GlobalService {
  fileUrl: string;

  constructor(private http: HttpClient) {
    this.fileUrl = enviroment.BASE_URL_API + '/global';
  }

  FindUseEmail(email:string): Observable<any> {
    const url = `${this.fileUrl}/email/${email}`;
    return this.http.get<any>(url);
  }

}
