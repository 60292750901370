import { Country, PhoneCountry } from "../country";
import { Reason } from "../reason";

export class Account {
    id: string;
    country: Country;
    name: string;
    docType: any | null;
    docNumber: string;
    birthDate: Date | null;
    phone: string;
    phoneCountry: PhoneCountry;
    email: string;
    password: string;
    passwordConfirm: string;
    language: any;
    checkCookies: boolean;
    checkNewsletter: boolean;
    inactive: boolean;
    inactiveReason: Reason | null;
    firstAccess: boolean;

    constructor(
        id: string = '',
        country: Country = new Country(),
        name: string = '',
        docType: any | null = null,
        docNumber: string = '',
        birthDate: Date | null = null,
        phone: string = '',
        phoneCountry: PhoneCountry = new PhoneCountry(),
        email: string = '',
        password: string = '',
        passwordConfirm: string = '',
        language: any = {},
        checkCookies: boolean = false,
        checkNewsletter: boolean = false,
        inactive: boolean = false,
        inactiveReason: Reason | null = null,
        firstAccess: boolean = false) {
        this.id = id;
        this.country = country;
        this.name = name;
        this.docType = docType;
        this.docNumber = docNumber;
        this.birthDate = birthDate;
        this.phone = phone;
        this.phoneCountry = phoneCountry;
        this.email = email;
        this.password = password;
        this.passwordConfirm = passwordConfirm;
        this.language = language;
        this.checkCookies = checkCookies;
        this.checkNewsletter = checkNewsletter;
        this.inactive = inactive;
        this.inactiveReason = inactiveReason;
        this.firstAccess = firstAccess;
    }
}
