export class Model {
    id: number;
    internationalizationID: string;
    description: string;
    created: string;

    constructor(id: number = 0, internationalizationID: string = '', description: string = '', created: string = '') {
        this.id = id;
        this.internationalizationID = internationalizationID;
        this.description = description;
        this.created = created;
    }
}