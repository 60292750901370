import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { Family } from '../model/family';
import { LanguageEnum } from '../model/enum/language-enum';
import { FamilyResponse } from '../model/response/family-response';
import { Item } from '../model/Item';

@Injectable({providedIn: 'root'})
export class FamilyService {

  httpOptions: {};
  familyUrl: string;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': 'Bearer ' + idToken;
      })
    };
    this.familyUrl = enviroment.BASE_URL_API + '/global/family';
  }

  getAllFamily() : Observable<FamilyResponse[]> {
    let lang = this.getLanguage(localStorage.getItem('language'));
    let idToken = this.getLanguage(localStorage.getItem('idToken'));

    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + idToken
      })
    };
    let url = this.familyUrl + this.getLanguageUrl(lang);

    return this.http.get<FamilyResponse[]>(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<FamilyResponse[]>(`getAllFamily`))
    );
  }

  static toFamily(acc: FamilyResponse) : Family {
    if(acc) {
      return new Family(
            acc.familyId,
            acc.familyDescription);
    } else {
      return new Family();
    }
  }

  static toFamilyList(ctrs: FamilyResponse[]) : Family[] {
    if(ctrs) {
      let familys: Family[] = [];
      ctrs.forEach(ct => familys.push(this.toFamily(ct)));
      return familys;
    } else {
      return [];
    }
  }

  static toItemList(ctrs: FamilyResponse[]) : Item[] {
    if(ctrs) {
      let familys: Item[] = [];
      ctrs.forEach(ct => familys.push(new Item(ct.familyId, ct.familyDescription)));
      return familys;
    } else {
      return [];
    }
  }

  private getLanguageUrl(language: string) {
      return '?Language=' + language;
  }

  private getLanguage(language: string | null) {
      return (language ? language : LanguageEnum.PT_BR);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
