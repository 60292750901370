import { Image } from "../Image";

export const IMAGE_1 = new Image(1, 'image1','assets/img/project/image01.png');
export const IMAGE_2 = new Image(2, 'image2','assets/img/project/image02.png');
export const IMAGE_3 = new Image(3, 'image3','assets/img/project/image03.png');
export const IMAGE_4 = new Image(4, 'image4','assets/img/project/image04.png');

export const IMAGE_5 = new Image(5, 'image5','assets/img/project/audi.png');
export const IMAGE_6 = new Image(6, 'image6','assets/img/project/cruze.png');
export const IMAGE_7 = new Image(7, 'image7','assets/img/project/punto.png');
export const IMAGE_8 = new Image(8, 'image8','assets/img/project/punto2.png');
export const IMAGE_9 = new Image(9, 'image9','assets/img/project/punto3.jpg');
export const IMAGE_10 = new Image(10, 'image10','assets/img/project/voyage.png');
export const IMAGE_11 = new Image(11, 'image11','assets/img/project/audi2.jpeg');
export const IMAGE_12 = new Image(12, 'image12','assets/img/ftCloud-FundoPreto-Textobranco.png');

export const DEFAULT_IMAGE = new Image(12, 'image12','assets/img/ftCloud-FundoPreto-Textobranco.png');
export const IMAGE_DEFAULT = new Image(0, 'default','assets/img/project/default-project.png');

export const IMAGE_LIST = [IMAGE_1, IMAGE_2, IMAGE_3, IMAGE_4]
export const IMAGE_AUDI_LIST = [IMAGE_5, IMAGE_11, IMAGE_6, IMAGE_7, IMAGE_8]
export const IMAGE_CRUZE_LIST = [IMAGE_6]
export const IMAGE_PUNTO_LIST = [IMAGE_7, IMAGE_8, IMAGE_9]
export const IMAGE_VOYAGE_LIST = [IMAGE_1, IMAGE_2, IMAGE_3, IMAGE_4, IMAGE_10]
export const IMAGE_DEFAULT_LIST = [IMAGE_12]