<p-dialog
    id="modal-welcome"
    name="modal-welcome"
    [modal]="true"
    [draggable]="false"
    [(visible)]="showDialog"
    (onHide)="closeModal()"
    [style]="{'border-radius':'130px'}">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-content-page1" [(style.display)]="modalpage[0]">
            <div class="modal-left">
                <!-- {{ form.value | json }} -->
                <h2>{{ 'project.register.vcuIdentified' | transloco }}</h2>
                <p>{{ 'project.register.vcuIdentifiedMsg' | transloco }}</p>
                <h4>{{ 'project.register.basicData' | transloco }}</h4>
                <div class="row">
                    <input
                        type="text" 
                        id="name" name="name"
                        formControlName="name"
                        placeholder="{{ 'project.register.name' | transloco }}"
                        pInputText >
                </div>
                <div class="row">
                    <input
                        type="text" 
                        id="instagram" name="instagram"
                        formControlName="instagram"
                        placeholder="{{ 'project.register.instagram' | transloco }}"
                        pInputText >
                </div>
                <div class="row">
                    <input
                        type="text" 
                        id="model" name="model"
                        formControlName="model"
                        placeholder="{{ 'project.register.model' | transloco }}"
                        pInputText >
                </div>
                <div class="btn-mobile">
                    <p-button
                        id="btnSubmitWelcomeMobile"
                        name="btnSubmitWelcomeMobile"
                        label="{{ 'vcu.welcome-btn' | transloco }}"
                        type="submit"
                        severity="danger"
                        [loading]="saveProjectBtnLoading"
                        [raised]="true">
                    </p-button>
                </div>
            </div>
            <div class="modal-right">
                <div class="img img1"></div>
            </div>
            <p-button
                id="btnSubmitProject"
                name="btnSubmitProject"
                label="{{ 'vcu.welcome-btn' | transloco }}"
                type="submit"
                severity="danger"
                [loading]="saveProjectBtnLoading"
                [raised]="true">
            </p-button>
        </div>
        <div class="modal-content-page2" [(style.display)]="modalpage[1]">
            <div class="modal-left">
                <h2>{{ 'project.register.vcuIdentified' | transloco }}</h2>
                <p>{{ 'project.register.vcuIdentifiedMsg' | transloco }}</p>
                <div class="row">
                    <p-fileUpload #fileUpload
                      id="upload"
                      name="upload"
                      (onSelect)="onSelectFile($event)"
                      (uploadHandler)="uploadFiles()"
                      chooseLabel="{{ 'project.register.upload.add' | transloco }}"
                      uploadLabel="{{ 'project.register.upload.send' | transloco }}"
                      cancelLabel="{{ 'project.register.upload.cancel' | transloco }}"
                      customUpload="true"
                      [multiple]="true"
                      accept="image/*"
                      maxFileSize="1000000">
                      <ng-template pTemplate="content">
                          <p-table
                              *ngIf="project && project.images.length;"
                              #dtImage
                              [value]="project.images"
                              dataKey="id"
                              [rows]="5"
                              [loading]="loadingImage"
                              [(selection)]="project.primaryImage"
                              [tableStyle]="{ 'min-width': '25rem', 'width':'100%' }"
                          >
                              <ng-template pTemplate="header">
                                  <tr>
                                      <th style="width:15%">
                                          <div class="center-column">
                                            {{ 'project.register.upload.primaryPhoto' | transloco }}
                                          </div>
                                      </th>
                                      <th style="width:35%">
                                          <div class="center-column">
                                            {{ 'project.register.upload.image' | transloco }}
                                          </div>
                                      </th>
                                      <th pSortableColumn="name" style="width:25%">
                                        {{ 'project.register.upload.name' | transloco }} <p-sortIcon field="date" />
                                      </th>
                                      <th style="width:15%">
                                        {{ 'project.register.upload.size' | transloco }}
                                      </th>
                                      <th style="width:10%">
                                          <div class="center-column">
                                            {{ 'project.register.upload.remove' | transloco }}
                                          </div>
                                      </th>
                                  </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-image>
                                  <tr>
                                      <td>
                                          <div class="center-column">
                                              <p-tableRadioButton [value]="image"/>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="center-column">
                                              <img src="{{ image.href }}" width="100px" height="auto"/>
                                          </div>
                                      </td>
                                      <td>
                                          {{ image.name }}
                                      </td>
                                      <td>
                                          {{ image.size }} bytes
                                      </td>
                                      <td>
                                          <div class="center-column">
                                              <p-button [rounded]="true" [text]="true" icon="pi pi-trash" (click)="removeImage(image)"/>
                                          </div>
                                      </td>
                                  </tr>
                              </ng-template>
                              <ng-template pTemplate="emptymessage">
                                  <tr>
                                      <td colspan="5">{{ 'project.register.upload.empty' | transloco }}.</td>
                                  </tr>
                              </ng-template>
                          </p-table>
                      </ng-template>
                    </p-fileUpload>
                    <!-- <p-messages id="messages"
                      [(value)]="message" 
                      [enableService]="false" 
                      [style]="{'font-size':'15px', 'width': '100%'}"/> -->
                </div>
                <div class="btn-mobile">
                    <p-button
                        id="btnSubmitProjectImageMobile"
                        name="btnSubmitProjectImageMobile"
                        label="{{ 'vcu.welcome-btn' | transloco }}"
                        severity="danger"
                        [loading]="saveProjectImageBtnLoading"
                        [raised]="true"
                        (click)="onSubmitImage()">
                    </p-button>
                </div>
            </div>
            <div class="modal-right">
                <div class="img img2"></div>
            </div>
            <p-button
                id="btnSubmitProjectImage"
                name="btnSubmitProjectImage"
                label="{{ 'vcu.welcome-btn' | transloco }}"
                type="submit"
                severity="danger"
                [loading]="saveProjectImageBtnLoading"
                [raised]="true"
                (click)="onSubmitImage()">
            </p-button>
        </div>
    </form>
</p-dialog>