import { Language } from "../language";

export class Login {
    email: string = '';
    password: string = '';
    language: Language = new Language();

    constructor(language: Language) {
        this.language = language
    }
}
