export class Reseller {
    id: number;
    name: string;
    created: Date | null;

    constructor(id: number = 0, name: string = '', created: Date | null = null) {
        this.id = id;
        this.name = name;
        this.created = created;
    }
}
