import { ProjectAttributes } from "../project-attributes";
import { ProjectLogs } from "../project-logs";
import { ProjectMapping } from "../project-mapping";
import { ProjectSharing } from "../project-sharing";
import { VcuAttributes } from 'src/app/model/vcu-attributes';

export class FindUserVcusGetResp {
    id?: string | null;
    serial?: string | null;
    nickname?: string | null;
    family?: { [key: string]: string } | null;
    reseller?: { [key: string]: string } | null;
    model?: { [key: string]: string } | null;
    invoiceNumber?: string | null;
    status?: string | null;
    purchaseDate?: string | null;
    createdDate?: string | null;
    lastAccess?: string | null;
    projects?: ProjectsFindUserVcusGetResp[] | null;
    vcuAttributes?: { [key: string]: string } | null;
}

export class ProjectsFindUserVcusGetResp {
    projectID?: string | null;
    projectName?: string | null;
    default?: boolean | null;
    status?: string | null;
    applicationID?: number | null;
    attributes?: ProjectAttributes | null;
    tags?: string[] | null;
    sharings?: ProjectSharing[] | null;
    maps?: ProjectMapping[] | null;
    logs?: ProjectLogs[] | null;
    primaryImage?: string | null;

    constructor(
        projectID?: string | null,
        projectName?: string | null,
        defaultt?: boolean | null,
        status?: string | null,
        applicationID?: number | null,
        attributes?: ProjectAttributes | null,
        tags?: string[] | null,
        sharings?: ProjectSharing[] | null,
        maps?: ProjectMapping[] | null,
        logs?: ProjectLogs[] | null,
        primaryImage?: string | null
    ) {
        this.projectID =  projectID;
        this.projectName = projectName;
        this.default = defaultt;
        this.status = status;
        this.applicationID = applicationID;
        this.attributes = attributes;
        this.tags = tags;
        this.sharings = sharings;
        this.maps = maps;
        this.logs = logs;
        this.primaryImage = primaryImage;
    }
}