<app-dashboard-template>
    <!-- {{ form.value | json }} -->
    <p-confirmDialog />
    <div id="add-vcu" class="cards-add-vcu" fragment="garage">
        <h1>{{ 'dashboard.myVcus' | transloco }}</h1>
        <p-button
            icon="pi pi-plus-circle" 
            id="addVcuBtn" name="addVcuBtn"
            [rounded]="true" 
            [text]="true" 
            size="large"
            (click)="createVcu()" />
    </div>
    <div class="overlay" *ngIf="loadingVcu">
      <p-progressSpinner styleClass="large-spinner"></p-progressSpinner>
    </div>
    <div class="cards-vcu">
        <div class="card-vcu" *ngFor="let vcu of vcus; let i = index">
            <app-card-vcu [vcu]="vcu" [showUnlink]="false"></app-card-vcu>
        </div>
    </div>
    <app-register-vcu [showDialog]="showVcuDialog" (closeDialog)="updateVcuDialog()" [vcu]="vcuEdit" [editMode]="editMode"></app-register-vcu>
</app-dashboard-template>