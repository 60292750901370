import { TrashTypeEnum } from "../enum/trash-type";


export class TrashRestoreRequest {
    id: string;
    type: number; 

    constructor(id: string, type: string) {
        this.id = id;
        this.type = TrashTypeEnum[type as keyof typeof TrashTypeEnum]; 
    }
}
