import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { Reseller } from '../model/reseller';
import { LanguageEnum } from '../model/enum/language-enum';
import { Item } from '../model/Item';
import { ResellerResponse } from '../model/response/reseller-response';

@Injectable({providedIn: 'root'})
export class ResellerService {

  httpOptions: {};
  resellerUrl: string;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': 'Bearer ' + idToken;
      })
    };
    this.resellerUrl = enviroment.BASE_URL_API + '/global/reseller';
  }

  getAllReseller() : Observable<ResellerResponse[]> {
    let lang = this.getLanguage(localStorage.getItem('language'));
    let idToken = this.getLanguage(localStorage.getItem('idToken'));

    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + idToken
      })
    };
    let url = this.resellerUrl + this.getLanguageUrl(lang);

    return this.http.get<ResellerResponse[]>(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<ResellerResponse[]>(`getAllReseller`))
    );
  }

  static toReseller(acc: ResellerResponse) : Reseller {
    if(acc) {
      return new Reseller(
            acc.id,
            acc.name,
          new Date(acc.created));
    } else {
      return new Reseller();
    }
  }

  static toResellerList(ctrs: ResellerResponse[]) : Reseller[] {
    if(ctrs) {
      let resellers: Reseller[] = [];
      ctrs.forEach(ct => resellers.push(this.toReseller(ct)));
      return resellers;
    } else {
      return [];
    }
  }

  static toItemList(ctrs: ResellerResponse[]) : Item[] {
    if(ctrs) {
      let resellers: Item[] = [];
      ctrs.forEach(ct => resellers.push(new Item(ct.id, ct.name)));
      return resellers;
    } else {
      return [];
    }
  }
  private getLanguageUrl(language: string) {
      return '?Language=' + language;
  }

  private getLanguage(language: string | null) {
      return (language ? language : LanguageEnum.PT_BR);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
