import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Message } from 'primeng/api';
import { Project } from 'src/app/model/form/project';
import { FileService } from 'src/app/service/file.service';
import { ProjectService } from 'src/app/service/project.service';

@Component({
  selector: 'app-vcu-identified',
  templateUrl: './vcu-identified.component.html',
  styleUrl: './vcu-identified.component.css'
})
export class VcuIdentifiedComponent implements OnInit  {

  @Input()
  showDialog: boolean = true;

  @Output()
  closeDialog = new EventEmitter<any>();

  public form!: FormGroup;

  project: Project | null;
  sidebarVisible2: boolean = true;
  loadingImage: boolean = false;
  modalpage: string[] = [];
  messages: Message[] = [];
  labelBtnSave: string = '';
  token: string | null = '';
  userID: string = '';
  saveProjectBtnLoading: boolean = false;
  saveProjectImageBtnLoading: boolean = false;
  selectedFiles: File[] | null = null;

  constructor(private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private projectService: ProjectService,
    private fileService: FileService) {

    this.project = new Project();
  }
  
  ngOnInit() {
    this.createForm(this.project);
    this.modalGoPage1();
    this.changeLanguage();
  }
  
  createForm(project: Project | null) {
    if(project) {
      this.form = this.formBuilder.group({
        name: [project.name],
        instagram: [project.instagram],
        model: [project.vehicle?.model],
        images: [project.images],
      })
    }
  }

  onSubmit() {
    if(this.f.name.value !== '') {
      this.saveProjectBtnLoading = true;
      this.userID = localStorage.getItem('idUser') ?? '';
      this.projectService.createProject(this.userID, this.form.value).subscribe({
        next: data => {
          this.project = ProjectService.toProject(data);
          this.saveProjectBtnLoading = false;
        }, error: _ => {
          this.saveProjectBtnLoading = false;
        }
      });
    }
    this.modalGoPage2();
  }

  closeModal() {
    this.form.reset();
    this.closeDialog.emit();
  }
  
  onSubmitImage() {
    this.closeModal();
  }

  onSelectFile(event: any) {
    if (event.files.length > 0) {
      this.selectedFiles = Array.from(event.files);
    }
  }

  uploadFiles() {
    if (this.selectedFiles !== null && this.selectedFiles.length > 0) {
      this.selectedFiles.forEach((file, index) => {
        let fileName = file.name;
        this.fileService.HeadFile(this.userID, 'tmp', 'photo', fileName).subscribe(
          (response: HttpResponse<any>) => {
            const uploadId = response.headers.get('upload_id');
            if (uploadId) {
              const partNumber: number = 1;
              this.fileService.PostFile(this.userID, 'tmp', 'photo', fileName, uploadId, partNumber, file).subscribe(
                (response: HttpResponse<any>) => {
                  const etagResponse = response.body;
                  if (etagResponse && etagResponse.ETag && etagResponse.PartNumber) {
                    let partsArray: { ETag: string, PartNumber: number }[] = [];
                    partsArray.push({ETag: etagResponse.ETag,PartNumber: etagResponse.PartNumber});
                    this.fileService.PutFile(this.userID, 'tmp', 'photo', fileName, uploadId, partsArray).subscribe(
                      (putResponse: HttpResponse<any>) => {
                      },
                      error => {
                        console.error('uploadFiles:::PutFile:::error > ', error);
                      }
                    );
                  }
                },
                error => {
                  console.error('uploadFiles:::PostFile:::error > ', error);
                }
              );
            }
          },
          error => {
            console.error('uploadFiles:::HeadFile:::error > ', error);
          }
        );
      });
    } else {
      console.error('Nenhum arquivo selecionado.');
    }
  }
  
  removeImage(image: File | any) {
    if(this.project) {
      let index = this.project.images.indexOf(image);
      this.project.images.splice(index, 1);
      if(image?.href === this.project.primaryImage?.href && this.project.images.length > 0) {
        this.project.primaryImage = this.project.images[0];
      }
    }
  }

  modalGoPage1() {
    this.modalpage = this.goPage(1);
  }

  modalGoPage2() {
    this.modalpage = this.goPage(2);
  }

  modalGoPage3() {
    this.modalpage = this.goPage(3);
  }

  modalGoPage4() {
    this.modalpage = this.goPage(4);
  }

  goPage(index: number) {
    let aux = ['none', 'none', 'none', 'none'];
    aux[index-1] = '';
    return aux;
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }
  get f() { return this.form.controls; }
}
