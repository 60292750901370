export class Country {
  id: number;
  name: string;
  code: string;
  dialCode: string;
  flag: string;
  mask: string;

  constructor(
    id: number = 0, 
    name: string = '',
    code: string = '',
    dialCode: string = '',
    flag: string = '',
    mask: string = ''
  ) {
      this.id = id;
      this.name = name;
      this.code = code;
      this.dialCode = dialCode;
      this.flag = flag;
      this.mask = mask;
  }
}

export class PhoneCountry {
  code: string;
  dialCode: string;
  flag: string;
  mask: string;
  name: string;

  constructor(
    code: string = '',
    dialCode: string = '',
    flag: string = '',
    mask: string = '',
    name: string = ''
  ) {
    this.code = code;
    this.dialCode = dialCode;
    this.flag = flag;
    this.mask = mask;
    this.name = name;
  }
}