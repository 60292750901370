export class Share {
    id: string;
    name: string;
    email: string;
    date: string | null;
    permission: string;

    constructor(
        id = '',
        name = '',
        email = '',
        date = '',
        permission = '',
    ) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.date = date;
        this.permission = permission;
    }
}
