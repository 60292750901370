import { ProjectAttributes } from "../project-attributes";
import { ProjectSharing } from "../project-sharing";
import { VcuRequest } from "./vcu-request";

export class ProjectRequest {
    name: string;
    default: boolean;
    attributes?: ProjectAttributes;
    sharings?: ProjectSharing[];
    tags?: string[];
    vcus?: VcuRequest[];
  
    constructor(
        name: string = 'Default',
        defaul: boolean = true,
        attributes?: ProjectAttributes,
        sharings?: ProjectSharing[],
        tags?: string[],
        vcus?: VcuRequest[]
    ) {
        this.name = name;
        this.default = defaul;
        this.attributes = attributes;
        this.sharings = sharings;
        this.tags = tags;
        this.vcus = vcus;
    }
}