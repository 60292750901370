export class Vehicle {
    id: number;
    manufacturer: string;
    model: string;
    yearDate: Date | null;

    constructor(
        id = 0,
        manufacturer = '',
        model = '',
        yearDate: Date | null = null
    ) {
        this.id = id;
        this.manufacturer = manufacturer;
        this.model = model,
        this.yearDate = yearDate;
    }
}
