<app-dashboard-template>
    <div class="g-container">
        <!-- <div class="g-imgs">
            <div class="g-img-card" *ngFor="let image of images">
                <img [src]="image.href" />
            </div>
        </div> -->
        <p-confirmDialog />
        <div class="overlay" *ngIf="loadingProject">
          <p-progressSpinner styleClass="large-spinner"></p-progressSpinner>
        </div>
        <p-carousel 
            [value]="project?.images ?? []" 
            [numVisible]="3" 
            [numScroll]="3" 
            [circular]="true" 
            [showIndicators]="false"
            [responsiveOptions]="responsiveOptions">
            <ng-template let-image pTemplate="item">
                <p-image 
                    [src]="image.href"
                    [alt]="image.name"
                    [width]="'100%'"
                />
            </ng-template>
        </p-carousel>
        <div class="g-card">
            <div class="g-header">
                <div class="g-left">
                    <h1>{{ project?.name }}</h1>                  
                    <!-- <div class="g-row">
                        <i class="pi pi-instagram" style="font-size: 2rem"></i>
                        <a [routerLink]="[]">voyabm</a>
                    </div> -->
                </div>
                <div class="g-right">
                    <div class="g-row">
                        <p-button class="shared" icon="pi pi-pencil" (click)="editProject()"/>
                        <p-button class="shared" icon="pi pi-trash"  (click)="disableProject()" *ngIf="!project?.favorite"/>
                        <p-button class="disabled" icon="pi pi-trash"  *ngIf="project?.favorite" pTooltip="{{ 'project.detail.messageDisable' | transloco }}" tooltipPosition="top"/>
                        <p-button class="shared" icon="pi pi-share-alt" [disabled]="" />
                    </div>
                </div>
            </div>
        </div>
        <p-accordion [multiple]="true" expandIcon="pi pi-fw pi-chevron-up" [activeIndex]="vcus.length > 0 ? [0] : null">
            <p-accordionTab header="{{ 'project.detail.vcu' | transloco }}" iconPos="end">
            <div class="cards-vcu">
                <div class="card-vcu" *ngFor="let vcu of vcus; let i = index">
                    <app-card-vcu [vcu]="vcu" [showUnlink]="true" (unlink)="dialogUnlink(i)"></app-card-vcu>
                </div>
                <!-- <div class="card-vcu" (click)="addVcu()">
                    <app-card-vcu [lastCard]="true" [lastCardLink]="true"></app-card-vcu>
                </div> -->
            </div>
            </p-accordionTab>
            <p-accordionTab header="{{ 'project.detail.map' | transloco }}" iconPos="end">
                <p-table
                    #dtMap
                    [value]="maps"
                    dataKey="id"
                    editMode="row"
                    [rows]="5"
                    [rowsPerPageOptions]="[5, 15, 30]"
                    [loading]="loadingMap"
                    [paginator]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{{ 'project.detail.tableMap.currentPage' | transloco }}"
                    [globalFilterFields]="['name', 'date']"
                    [tableStyle]="{ 'min-width': '75rem' }"
                >
                    <ng-template pTemplate="caption">
                        <div class="g-table-end">
                            <p-button [rounded]="true" [text]="true" icon="pi pi-cloud-upload" (click)="triggerFileUpload('map')" />
                            <input type="file" #fileInputMap style="display: none;" (change)="handleFileInput($event,'map')" />
                            <p-iconField iconPosition="right" class="ml-auto">
                                <p-inputIcon>
                                    <i class="pi pi-search"></i>
                                </p-inputIcon>
                                <input 
                                    pInputText 
                                    type="text" 
                                    (input)="applyFilterGlobalMap($event, 'contains')" 
                                    placeholder="{{ 'project.detail.tableMap.search' | transloco }}" />
                            </p-iconField>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name" style="width:25%">
                                {{ 'project.detail.tableMap.name' | transloco }} <p-sortIcon field="name" />
                            </th>
                            <th pSortableColumn="date" style="width:20%">
                                {{ 'project.detail.tableMap.date' | transloco }} <p-sortIcon field="date" />
                            </th>
                            <th style="width:15%">{{ 'project.detail.tableMap.action' | transloco }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-map let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="map">
                            <td>
                                {{ map.name }}
                            </td>
                            <td>
                                {{ map.date }}
                            </td>
                            <td>
                                <p-button [rounded]="true" [text]="true" icon="pi pi-download" (click)="onRowDownload('map', ri)"/>
                                <p-button [rounded]="true" [text]="true" icon="pi pi-trash"(click)="onRowRemoveMap($event, ri)"/>
                                <!-- <p-button *ngIf="!editing" [rounded]="true" [text]="true" 
                                    icon="pi pi-pencil"
                                    (click)="onRowEditInitMap(map)"
                                    pInitEditableRow  
                                />
                                <p-button 
                                    *ngIf="editing" [rounded]="true" [text]="true"
                                    icon="pi pi-check"
                                    (click)="onRowEditSaveMap(map)" 
                                    [style]="{'color':'green'}"
                                    pSaveEditableRow 
                                />
                                <p-button 
                                    *ngIf="editing" [rounded]="true" [text]="true"
                                    icon="pi pi-times" 
                                    (click)="onRowEditCancelMap(ri)"
                                    pCancelEditableRow 
                                /> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5">{{ 'project.detail.tableMap.noFound' | transloco }}.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>            
            <p-accordionTab header="{{ 'project.detail.log' | transloco }}" iconPos="end">
                <p-table
                    #dtLog
                    [value]="logs"
                    dataKey="id"
                    editMode="row"
                    [rows]="5"
                    [rowsPerPageOptions]="[5, 15, 30]"
                    [loading]="loadingLog"
                    [paginator]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{{ 'project.detail.tableLog.currentPage' | transloco }}"
                    [globalFilterFields]="['name', 'date']"
                    [tableStyle]="{ 'min-width': '75rem' }"
                >
                    <ng-template pTemplate="caption">
                        <div class="g-table-end">
                            <p-button [rounded]="true" [text]="true" icon="pi pi-cloud-upload" (click)="triggerFileUpload('log')" />
                            <input type="file" #fileInputLog style="display: none;" (change)="handleFileInput($event,'log')" />
                            <p-iconField iconPosition="right" class="ml-auto">
                                <p-inputIcon>
                                    <i class="pi pi-search"></i>
                                </p-inputIcon>
                                <input 
                                    pInputText 
                                    type="text" 
                                    (input)="applyFilterGlobalLog($event, 'contains')" 
                                    placeholder="{{ 'project.detail.tableLog.search' | transloco }}" />
                            </p-iconField>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name" style="width:25%">
                                {{ 'project.detail.tableLog.name' | transloco }} <p-sortIcon field="name" />
                            </th>
                            <th pSortableColumn="date" style="width:20%">
                                {{ 'project.detail.tableLog.date' | transloco }} <p-sortIcon field="date" />
                            </th>
                            <th style="width:15%">{{ 'project.detail.tableLog.action' | transloco }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-log let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="log">
                            <td>
                                {{ log.name }}
                            </td>
                            <td>
                                {{ log.date }}
                            </td>
                            <td>
                                <p-button [rounded]="true" [text]="true" icon="pi pi-download" (click)="onRowDownload('log', ri)"/>
                                <p-button [rounded]="true" [text]="true" icon="pi pi-trash" (click)="onRowRemoveLog($event, ri)"/>
                                <!-- <p-button *ngIf="!editing" [rounded]="true" [text]="true" 
                                    icon="pi pi-pencil"
                                    (click)="onRowEditInitLog(log)"
                                    pInitEditableRow  
                                    [disabled]="true"
                                />
                                <p-button 
                                    *ngIf="editing" [rounded]="true" [text]="true"
                                    icon="pi pi-check"
                                    (click)="onRowEditSaveLog(log)" 
                                    [style]="{'color':'green'}"
                                    pSaveEditableRow 
                                /> -->
                                <p-button 
                                    *ngIf="editing" [rounded]="true" [text]="true"
                                    icon="pi pi-times" 
                                    (click)="onRowEditCancelLog(ri)"
                                    pCancelEditableRow 
                                />
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5">{{ 'project.detail.tableLog.noFound' | transloco }}.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <!-- TODO: Equipamento será refatorado -->
            <!-- <p-accordionTab header="{{ 'project.detail.equipment' | transloco }}" iconPos="end">
            </p-accordionTab> -->
            <p-accordionTab header="{{ 'project.detail.share' | transloco }}" iconPos="end">
                <div>
                    <!-- {{ form.value | json }} -->
                    <div class="v-row">
                    <label>{{ 'project.register.shareLabel' | transloco }}</label>
                    </div>
                    <div class="v-row-email">
                        <input class="email" 
                            type="email"
                            id="email" name="email"
                            [(ngModel)]="emailShare" 
                            placeholder="{{ 'project.register.email' | transloco }}" 
                            pInputText />
                        <p-button
                            id="btnAdd"
                            name="btnAdd"
                            label="{{ 'project.register.addBtn' | transloco }}"
                            [raised]="true"
                            (click)="addShare()"/>
                    </div>
                </div>
                <p-table
                    #dtShare
                    [value]="shares"
                    dataKey="id"
                    [rows]="5"
                    [rowsPerPageOptions]="[5, 15, 30]"
                    [loading]="loadingShare"
                    [paginator]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{{ 'project.detail.tableShare.currentPage' | transloco }}"
                    [globalFilterFields]="['name', 'email', 'date']"
                    [tableStyle]="{ 'min-width': '75rem' }"
                >
                    <ng-template pTemplate="caption">
                        <div class="g-table-end">
                            <p-iconField iconPosition="right" class="ml-auto">
                                <p-inputIcon>
                                    <i class="pi pi-search"></i>
                                </p-inputIcon>
                                <input 
                                    pInputText 
                                    type="text" 
                                    (input)="applyFilterGlobalShare($event, 'contains')" 
                                    placeholder="{{ 'project.detail.tableShare.search' | transloco }}" />
                            </p-iconField>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name" style="width:30%">
                                {{ 'project.detail.tableShare.name' | transloco }} <p-sortIcon field="name" />
                            </th>
                            <th pSortableColumn="email" style="width:35%">
                                {{ 'project.detail.tableShare.email' | transloco }} <p-sortIcon field="email" />
                            </th>
                            <th pSortableColumn="date" style="width:20%">
                                {{ 'project.detail.tableShare.date' | transloco }} <p-sortIcon field="date" />
                            </th>
                            <th style="width:15%">{{ 'project.detail.tableShare.action' | transloco }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-share let-ri="rowIndex">
                        <tr>
                            <td>
                                {{ share.name }}
                            </td>
                            <td>
                                {{ share.email }}
                            </td>
                            <td>
                                {{ share.date }}
                            </td>
                            <td>
                                <p-button [rounded]="true" [text]="true" icon="pi pi-trash" (click)="onRowRemoveShare($event, ri)"/>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5">{{ 'project.detail.tableShare.noFound' | transloco }}.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
        </p-accordion>
    </div>
    <p-dialog header="{{ 'vcu.detail.unlinkVcu.header' | transloco }}" [modal]="true" [(visible)]="showDialogUnlink" [style]="{ width: '25rem' }">
        <div class="dialog-btns">
            <p-button id="dialog-cancel" label="{{ 'vcu.detail.unlinkVcu.cancel' | transloco }}" severity="secondary" (onClick)="showDialogUnlink = false" />
            <p-button id="dialog-save" label="{{ 'vcu.detail.unlinkVcu.save' | transloco }}" [loading]="unlinkVcuBtnLoading" (onClick)="unlinkVCU()" />
        </div>
    </p-dialog>
    <p-toast key="toastProject" />
    <app-register-vcu [showDialog]="showVcuDialog" (closeDialog)="updateVcuDialog()" [vcu]="null" [editMode]="false"></app-register-vcu>
    <app-register-project [showDialog]="showEditProjectDialog" (closeDialog)="closeProjectDialog()" [project]="project" [editMode]="true"></app-register-project>
</app-dashboard-template>