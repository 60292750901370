import { LanguageEnum } from "./enum/language-enum";

export class Language {
    id: number;
    name: string;
    class: string;
    disabled: boolean;

    constructor(id: number = 0, name: string = LanguageEnum.EN_US, classs: string = '', disabled: boolean = false) {
        this.id = id;
        this.name = name;
        this.class = classs;
        this.disabled = disabled;
    }
}
