import { AbstractControl, FormArray, ValidatorFn, Validators } from "@angular/forms";

export class CustomValidator {

    public static getEmail() {
        return [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(250),
            Validators.email
        ];
    }
    
    public static getEmailShare() {
        return [
            Validators.minLength(5),
            Validators.maxLength(250),
            Validators.email
        ];
    }

    public static getRequired() {
        return [
            Validators.required
        ];
    }

    public static getCheckbox() {
        return [
            Validators.required,
            Validators.requiredTrue
        ]
    }

    public static getPassword() {
        return [
            Validators.required,
            Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-]).{8,}$/)
        ]
    }

    public static fullName(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} => {
            if (control.toString().split(" ").length > 1) 
                return { fullName: true };
            return { fullName: false };
        }
    }

    // public static getNotEmpty(size: number) {
    //     return [
    //         Validators.minLengthArray(size)
    //     ];
    // }

    // private minLengthArray(min: number) {
    //     return (c: AbstractControl): {[key: string]: any} => {
    //         if (c.value.length >= min)
    //             return { 'minLengthArray': {valid: true }};
    
    //         return { 'minLengthArray': {valid: false }};
    //     }
    // }

    // max length
    public static maxLength(max: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormArray)) return;
            return control.length > max ? { maxLength: true } : null;
        }
    }

    // min length
    public static minLength(min: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormArray)) return;
            return control.length < min ? { minLength: true } : null;
        }
    }
}
