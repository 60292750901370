import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { Model } from '../model/model';
import { LanguageEnum } from '../model/enum/language-enum';
import { ModelResponse } from '../model/response/model-response';
import { Item } from '../model/Item';

@Injectable({providedIn: 'root'})
export class ModelService {

  httpOptions: {};
  modelUrl: string;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': 'Bearer ' + idToken;
      })
    };
    this.modelUrl = enviroment.BASE_URL_API + '/global/vcu/model';
  }

  getAllModel() : Observable<ModelResponse[]> {
    let lang = this.getLanguage(localStorage.getItem('language'));
    let idToken = this.getLanguage(localStorage.getItem('idToken'));

    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + idToken
      })
    };
    let url = this.modelUrl + this.getLanguageUrl(lang);

    return this.http.get<ModelResponse[]>(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<ModelResponse[]>(`getAllModel`))
    );
  }

  static toModel(acc: ModelResponse) : Model {
    if(acc) {
      return new Model(
            acc.modelID,
            acc.internationalizationID,
            acc.modelDescription,
            acc.modelCreated);
    } else {
      return new Model();
    }
  }

  static toModelList(ctrs: ModelResponse[]) : Model[] {
    if(ctrs) {
      let models: Model[] = [];
      ctrs.forEach(ct => models.push(this.toModel(ct)));
      return models;
    } else {
      return [];
    }
  }

  static toItemList(ctrs: ModelResponse[]) : Item[] {
    if(ctrs) {
      let models: Item[] = [];
      ctrs.forEach(ct => models.push(new Item(ct.modelID, ct.modelDescription)));
      return models;
    } else {
      return [];
    }
  }

  private getLanguageUrl(language: string) {
      return '?Language=' + language;
  }

  private getLanguage(language: string | null) {
      return (language ? language : LanguageEnum.PT_BR);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
