import { Image } from "../Image";
import { Item } from "../Item";

export class Load {
    
    static IMAGE_FT700 = new Image(0, 'FT700', 'assets/img/ft/FT700.png');
    static IMAGE_FT700_PLUS = new Image(1, 'FT700 PLUS', 'assets/img/ft/FT700PLUS.png');
    static IMAGE_FT450 = new Image(2, 'FT 450', 'assets/img/ft/FT450.png');
    static IMAGE_FT550 = new Image(3, 'FT 550', 'assets/img/ft/FT450.png');
    static IMAGE_FT550_LITE = new Image(4, 'FT550 LITE', 'assets/img/ft/FT450.png');
    static IMAGE_FT600 = new Image(5, 'FT 600', 'assets/img/ft/FT450.png');
    static IMAGE_FT300 = new Image(6, 'FT 300', 'assets/img/ft/FT450.png');

    static FAMILY = [
        new Item(0, 'VisionFT'),
        new Item(1, 'PowerFT'),
        new Item(3, 'FT300')
    ];
    static FAMILY_VISION = new Item(0, 'VisionFT');
    static FAMILY_POWER = new Item(1, 'PowerFT');
    static FAMILY_300 = new Item(3, 'FT300');
    
    static MODEL_VISION = [
        new Item(0, 'FT700')
    ];
    static MODEL_POWER = [
        new Item(0, 'FT450'),
        new Item(1, 'FT550'),
        new Item(3, 'FT550 LITE'),
        new Item(4, 'FT600')
    ];
    static MODEL_300 = [
        new Item(0, 'FT300')
    ];
    
    static DEALER = [
        new Item(0, 'Revendedor1'),
        new Item(1, 'Revendedor2')
    ];

    static APPLICATION_1 = new Item(0, 'Aplication1');
    static APPLICATION_2 = new Item(1, 'Aplication2');

    static APPLICATION = [
        new Item(0, 'Aplication1'),
        new Item(1, 'Aplication2')
    ];
}