import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Project } from 'src/app/model/form/project';
import { Image } from 'src/app/model/Image';
import { Share } from 'src/app/model/share';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjectService } from 'src/app/service/project.service';
import { UpdateProjectPutReq } from 'src/app/model/request/update-project-put-req';

@Component({
  selector: 'app-card-project',
  templateUrl: './card-project.component.html',
  styleUrl: './card-project.component.css'
})
export class CardProjectComponent implements OnInit {

  @Input() project: Project | undefined;
  @Input() user: string | null = '';
  @Output() refreshDashboard: EventEmitter<void> = new EventEmitter<void>();

  disabledShare: boolean;
  sharedClass: string = '';
  equipments: string[] = [];
  imageStyle: string = '';
  subtitleClass: string = '';
  h1Class: string = '';
  projectUrl: string;
  userID: string = '';

  constructor(
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private projectService: ProjectService,
    private confirmationService: ConfirmationService,
    private router: Router) {

    this.disabledShare = false;
    this.projectUrl = "/project"

  }
  
  ngOnInit(): void {
    this.userID = localStorage.getItem('idUser') ?? '';
    this.messageService.clear();
    this.changeLanguage();
    this.createCard();
  }
  
  createCard() {
    this.equipments = this.getEquipments();
    if(this.project && this.project != null && this.user && this.user != null) {
      this.imageStyle = this.projectService.setImageCard(this.project, this.user)
    }
    this.isSharedBy(this.project?.sharedBy);
  }

  getEquipments() : string[] {
    let equips = new Array<string>()
    this.project?.vcus.forEach(vcu => {
      if(vcu.model && vcu.model.name)
        equips.push(vcu.model.name)
    });
    this.project?.equipments.forEach(equipment => {
      equips.push(equipment.name)
    });
    return equips;
  }


  isSharedBy(share: Share | undefined | null) {
    if(share !== null && share?.email !== '') {
      this.disabledShare = true;
      this.subtitleClass = 'subtitle-show';
    } else {
      this.h1Class = 'margin-h1';
    }
  }

  goProjectDetail() {
    if(this.project)
      this.router.navigate(['/project/', this.project.id]);
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }
  
  showConfirm() {
    if(this.project?.favorite == false){
      this.translocoService.selectTranslateObject('project.card.default').subscribe(value => 
        this.confirmationService.confirm({
          message: value.message,
          header: value.header,
          icon: 'pi pi-info-circle',
          acceptButtonStyleClass:"p-button-danger",
          rejectButtonStyleClass:"p-button-text",
          acceptLabel: value.yes,
          rejectLabel: value.no,
          acceptIcon:"none",
          rejectIcon:"none",
          accept: () => {
            this.confirmationService.close();
            let userID = localStorage.getItem('idUser');
            if(userID){
              let updateProject = new UpdateProjectPutReq();
              updateProject.default = true;
              this.projectService.updateProject(userID,this.project?.id!,updateProject).subscribe({
                next: _ => {
                  this.refreshDashboard.emit();
                  setTimeout(() => {
                    this.messageService.clear();
                    this.messageService.add({ key: 'toast', severity: 'success', summary: value.header, detail: value.confirm});
                  }, 3000);
                },
                error: _ =>{
                  this.messageService.clear();
                  this.messageService.add({ key: 'toast', severity: 'error', summary: value.header, detail: value.error});
                }
              });
            }
          },
          reject: () => {
            this.confirmationService.close();
          }
        })
      ).unsubscribe();
    }
  }
}
