import { DocTypeEnum } from "./enum/doc-type";

export class DocType {
    id: number;
    name: string;

    constructor() {
        this.id = 0;
        this. name = '';
    }

    static CPF = { id: DocTypeEnum.CPF, name: 'CPF'  } as DocType;
    static CNPJ = { id: DocTypeEnum.CNPJ, name: 'CNPJ' } as DocType;

    static DOCTYPE_LIST = [
        { id: DocTypeEnum.CPF, name: 'CPF'  } as DocType,
        { id: DocTypeEnum.CNPJ, name: 'CNPJ' } as DocType
    ];
}
