export class Image {
    id?: number;
    name: string;
    href?: string;
    size?: number;
  
    constructor(
        id?: number, 
        name: string = '', 
        href?: string, 
        size?: number
    ) {
        this.id = id;
        this.name = name;
        this.href = href;
        this.size = size;
    }
}