import { Status } from "../enum/status";
import { Image } from "../Image";
import { Item } from "../Item";
import { VcuAttributes } from "../vcu-attributes";
import { Project } from "./project";

export class Vcu {
    id: string;
    image: Image | null;
    surname: string;
    family: Item | null;
    model: Item | null;
    serialNumber: string;
    reseller: Item | null;
    invoice: string;
    purchaseDate: Date | null;
    tags: string[];
    generalVersionDate: Date | null;
    status: Status | null;
    createdDate: Date | null;
    lastAcessDate: Date | null;
    // linked: boolean;
    // reasonLinked: {};
    vcuAttributes: VcuAttributes | null;
    projects: Project[];

    constructor(
        id: string = '',
        image: Image | null = null,
        surname: string = '',
        family: Item | null = null,
        model: Item | null = null,
        serialNumber: string = '',
        reseller: Item | null = null,
        invoice: string = '',
        purchaseDate: Date | null = null,
        tags = new Array<string>(),
        generalVersionDate: Date | null = null,
        status: Status | null = null,
        createdDate: Date | null = null,
        lastAcessDate: Date | null = null,
        // linked = false,
        // reasonLinked = {},
        vcuAttributes: VcuAttributes | null = null,
        projects: Project[] = []
    ) {
        this.id = id;
        this.image = image;
        this.surname = surname;
        this.family = family;
        this.model = model;
        this.serialNumber = serialNumber;
        this.reseller = reseller;
        this.invoice = invoice;
        this.purchaseDate = purchaseDate;
        this.tags = tags;
        this.generalVersionDate = generalVersionDate;
        this.status = status;
        this.createdDate = createdDate;
        this.lastAcessDate = lastAcessDate;
        // this.linked = linked;
        // this.reasonLinked = reasonLinked;
        this.vcuAttributes = vcuAttributes;
        this.projects = projects;
    }
}
