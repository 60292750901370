
export class LogProject {
  id: string;
  name: string;
  project: string;
  tags: string[];
  date: string;
  
  constructor(id: string, name: string, project: string, tags: string[], date: string) {
    this.id = id;
    this.name = name;
    this.project = project;
    this.tags = tags;
    this.date = date;
  }
}