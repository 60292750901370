import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './module/user/login/login.component';
import { CreateAccountComponent } from './module/user/create-account/create-account.component';
import { AuthGuard } from './service/auth.service';
import { DashboardComponent } from './module/dashboard/dashboard/dashboard.component';
import { ActivateAccountComponent } from './module/user/activate-account/activate-account.component';
import { CardVcuComponent } from './module/card/card-vcu/card-vcu.component';
import { ForgotPasswordComponent } from './module/user/forgot-password/forgot-password.component';
import { CardProjectComponent } from './module/card/card-project/card-project.component';
import { DashboardTemplateComponent } from './module/dashboard/dashboard-template/dashboard-template.component';
import { ProjectComponent } from './module/project/project/project.component';
import { VcuComponent } from './module/vcu/vcu/vcu.component';
import { AccountComponent } from './module/user/account/account.component';
import { VcuManagementComponent } from './module/vcu/vcu-management/vcu-management.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'create-account', component: CreateAccountComponent},
  { path: 'activate-account', component: ActivateAccountComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate : [AuthGuard]}, // , canActivate : [AuthGuard]
  // { path: 'card-vcu', component: CardVcuComponent}, // remove
  // { path: 'card-project', component: CardProjectComponent}, // remove
  // { path: 'template', component: DashboardTemplateComponent}, // remove
  { path: 'vcu-management', component: VcuManagementComponent, canActivate : [AuthGuard]},
  { path: 'project/:id', component: ProjectComponent, canActivate : [AuthGuard]},
  { path: 'vcu/:id', component: VcuComponent, canActivate : [AuthGuard]},
  { path: 'profile/:id', component: AccountComponent, canActivate : [AuthGuard]},
  { path: '', redirectTo:'/login', pathMatch:'full' },
  
    // otherwise redirect to home
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
