import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    constructor(private http: HttpClient) {}

    getCountry() {
        return this.http.get('https://ipapi.co/json/'); // API de geolocalização
    }
}