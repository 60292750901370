import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { LanguageEnum } from '../model/enum/language-enum';
import { Reason } from '../model/reason';
import { ReasonResponse } from '../model/response/reason-response';

@Injectable({providedIn: 'root'})
export class ReasonService {

  httpOptions: {};
  reasonUrl: string;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': 'Bearer ' + idToken;
      })
    };
    this.reasonUrl = enviroment.BASE_URL_API + '/global/reason';
  }

  getAllReason() : Observable<ReasonResponse[]> {
    let lang = this.getLanguage(localStorage.getItem('language'));
    let idToken = localStorage.getItem('idToken');

    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + idToken
      })
    };
    let url = this.reasonUrl + this.getLanguageUrl(lang);

    return this.http.get<ReasonResponse[]>(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<ReasonResponse[]>(`getAllReason`))
    );
  }

  static toReason(reason: ReasonResponse) : Reason {
    if(reason) {
      return new Reason(
            reason.reasonId,
            reason.internationalizationId,
            reason.reasonDescription);
    } else {
      return new Reason();
    }
  }

  static toReasonList(ctrs: ReasonResponse[]) : Reason[] {
    if(ctrs) {
      let reasons: Reason[] = [];
      ctrs.forEach(ct => reasons.push(this.toReason(ct)));
      return reasons;
    } else {
      return [];
    }
  }

  private getLanguageUrl(language: string) {
      return '?Language=' + language;
  }

  private getLanguage(language: string | null) {
      return (language ? language : LanguageEnum.PT_BR);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
