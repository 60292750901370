import { CreProUserSharingPostReq } from './cre-pro-user-sharing-post-req';
import { CreProVcuPostReq } from './cre-pro-vcu-post-req';

export class CreateProjectPostReq {
  applicationID?: number | null;
  name?: string | null;
  default?: boolean | null;
  attributes?: { [key: string]: string } | null;
  sharings?: CreProUserSharingPostReq[] | null;
  tags?: string[] | null;
  vcus?: CreProVcuPostReq[] | null;
  equipmentIDs?: number[] | null;
}
