export class User {
    id: string = '';
    name: string = '';
    email: string = '';
    password: string = '';
    code: string = '';
    token: string = '';
    refreshToken: string = '';
    usernameAWS: string = '';
    language: string = '';
}