import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDatePipe } from './pipes/dynamic-date.pipe'; 

@NgModule({
  declarations: [
    DynamicDatePipe, 
  ],
  imports: [
    CommonModule, 
  ],
  exports: [
    DynamicDatePipe, 
  ],
})
export class SharedModule {}
