import { UpdProUserSharingPutReq } from './upd-pro-user-sharing-put-req';
import { UpdProVcuPutReq } from './upd-pro-vcu-put-req';

export class UpdateProjectPutReq {
  applicationID?: number | null;
  name?: string | null;
  default?: boolean | null;
  attributes?: { [key: string]: string } | null;
  sharings?: UpdProUserSharingPutReq[] | null;
  tags?: string[] | null;
  vcus?: UpdProVcuPutReq[] | null;
  equipmentIDs?: number[] | null;
}